import React, { useState, useEffect } from 'react';
import { firestore, auth } from '../../firebase/firebaseConfig';
import { collection, doc, addDoc, setDoc, deleteDoc, updateDoc, getDoc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Input } from 'antd';

function AumsCompanies () {
    let [ vendors, setVendors ] = useState( [] );
    let [ addObject, setAddObject ] = useState( {} );

    async function onLoad () {
        onSnapshot( collection( firestore, "aums_company" ), ( snapshot ) => {
            let vendorsArray = [];
            snapshot.forEach( ( element ) => {
                let vendorObject = {
                    name: element.data().name,
                    dealership_name: element.data().dealership_name,
                    dealership_mobile: element.data().dealership_mobile,
                    dealership_email: element.data().dealership_email,
                    rto: element.data().rto,
                    id: element.id
                }
                vendorsArray.push( vendorObject );
            } );
            setVendors( [ ...vendorsArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 12 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onLoad();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className='making-container'>
            <form onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                let check = await getDoc( doc( firestore, "aums_allowed_users", "+91" + addObject.dealership_mobile ) );
                if ( !check.exists() ) {
                    let docId = await addDoc( collection( firestore, "aums_company" ), {
                        name: addObject.name,
                        dealership_name: addObject.dealership_name,
                        dealership_mobile: "+91" + addObject.dealership_mobile,
                        dealership_email: addObject.dealership_email,
                        rto: addObject.rto
                    } );

                    let companyName = addObject.dealership_name.split( " " );
                    let companyCode = "";
                    if ( companyName.length === 1 ) {
                        if ( companyName[ 0 ].length >= 3 ) {
                            companyCode = companyName[ 0 ].substring( 0, 3 ).toUpperCase();
                        } else if ( companyName[ 0 ].length === 2 ) {
                            companyCode = companyName[ 0 ].substring( 0, 2 ).toUpperCase() + "O";
                        } else if ( companyName[ 0 ].length === 1 ) {
                            companyCode = companyName[ 0 ].substring( 0, 2 ).toUpperCase() + "OO";
                        }
                    } else if ( companyName.length === 2 ) {
                        if ( companyName[ 0 ].length >= 2 ) {
                            companyCode = companyName[ 0 ].substring( 0, 2 ).toUpperCase() + companyName[ 1 ].substring( 0, 1 ).toUpperCase();
                        } else if ( companyName[ 0 ].length === 1 && companyName[ 1 ].length >= 2 ) {
                            companyCode = companyName[ 0 ].substring( 0, 1 ).toUpperCase() + companyName[ 1 ].substring( 0, 2 ).toUpperCase();
                        } else if ( companyName[ 0 ].length === 1 && companyName[ 1 ].length === 1 ) {
                            companyCode = companyName[ 0 ].substring( 0, 1 ).toUpperCase() + companyName[ 1 ].substring( 0, 1 ).toUpperCase() + "O";
                        }
                    } else {
                        for ( let i = 0; i < 3; i++ ) {
                            companyCode += companyName[ i ].substring( 0, 1 ).toUpperCase();
                        }
                    }

                    await updateDoc( doc( firestore, "aums_company", docId.id ), {
                        uid: docId.id
                    } )

                    await setDoc( doc( firestore, "aums_company/" + docId.id + "/counter", "count" ), {
                        booking_counter: 0,
                        booking_left_counter: 0,
                        booking_prefix: companyCode + "BK23",
                        lead_counter: 0,
                        lead_left_counter: 0,
                        lead_prefix: companyCode + "LD23"
                    } );

                    await setDoc( doc( firestore, "aums_allowed_users", "+91" + addObject.dealership_mobile ), {
                        companyIds: docId.id,
                        companyNames: addObject.dealership_name,
                        name: "Admin",
                        phone: "+91" + addObject.dealership_mobile
                    } )

                    event.target.reset();
                    document.querySelector( ".sending-loader" ).style.display = "none";
                } else {

                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }} className="making-container-top-container">
                <Input style={{ width: "180px" }} type="text" name="name" className='name' placeholder="Name" onChange={event => {
                    setAddObject( { ...addObject, name: event.target.value } );
                }} required />
                <Input style={{ width: "180px" }} type="text" name="dealership_name" className='dealership_name' placeholder="Dealership Name" onChange={event => {
                    setAddObject( { ...addObject, dealership_name: event.target.value } );
                }} required />
                <Input style={{ width: "180px" }} type="text" name="phone" className='phone' placeholder="Dealership Phone Number" value={addObject.dealership_mobile ?? ""} minLength={10} maxLength={10} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setAddObject( { ...addObject, dealership_mobile: event.target.value } );
                    }
                }} required />
                <Input style={{ width: "180px" }} type="email" name="email" className='vendor_email' placeholder="Dealership Email" onChange={event => {
                    setAddObject( { ...addObject, dealership_email: event.target.value } );
                }} required />
                <Input style={{ width: "180px" }} type="text" placeholder='Dealership RTO Zone' name="rto" onChange={event => {
                    setAddObject( { ...addObject, rto: event.target.value } );
                }} required />
                <Button htmlType="submit">Add Company</Button>
            </form>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Aums Companies
            </div>
            <div className="all-make-entries-container">
                {vendors.map( ( element, index ) => (
                    <div style={{ alignItems: "center", width: "100%", justifyContent: "space-between" }} className='all-make-entry-container'>
                        <Input style={{ width: "180px" }} type="text" name="name" className='name' placeholder="Name" value={element.name} readOnly />
                        <Input style={{ width: "180px" }} type="text" name="dealership_name" className='dealership_name' placeholder="Dealership Name" value={element.dealership_name} readOnly />
                        <Input style={{ width: "180px" }} type="text" name="phone" className='phone' placeholder="Dealership Phone Number" value={element.dealership_mobile} readOnly />
                        <Input style={{ width: "180px" }} type="email" name="email" className='vendor_email' placeholder="Dealership Email" value={element.dealership_email} readOnly />
                        <Input style={{ width: "180px" }} type="text" placeholder='Dealership RTO Zone' name="rto" value={element.rto} readOnly />
                        <Button onClick={async event => {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            await deleteDoc( doc( firestore, "aums_company", element.id ) );
                            await deleteDoc( doc( firestore, "aums_allowed_users", element.dealership_mobile ) );
                            document.querySelector( ".sending-loader" ).style.display = "none";
                        }} danger>Delete Company</Button>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default AumsCompanies