import { BrowserRouter, Routes, Route, Link } from 'react-router-dom';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import "zone.js";
import Make from './Components/Make/Make';
import Editing from './Components/Editing/Editing';
import Vendors from './Components/Vendors/Vendors';
import Customers from './Components/Customers/Customers';
import Banking from './Components/Banking/Banking';
import Login from './Components/Login/Login';
import Accessories from './Components/Accessories/Accessories';
import Suppliers from './Components/Suppliers/Supplier';
import InsuranceCompanies from './Components/InsuranceCompanies/InsuranceCompanies';
import Events from './Components/Events/Events';
import Documents from './Components/Documents/Documents';
import ReligionSubReligion from './Components/Religion&SubReligion/Religion&SubReligion';
import AumsCompanies from './Components/AumsCompanies/AumsCompanies';
import { useEffect, useState } from 'react';
import { MenuOutlined, FileAddOutlined, EditOutlined, UsergroupAddOutlined, AppstoreAddOutlined, BankOutlined, InsuranceOutlined, CalendarOutlined, FilePdfOutlined, BuildOutlined, LogoutOutlined, FileDoneOutlined } from '@ant-design/icons';
import { FaMotorcycle } from 'react-icons/fa';
import { Layout, Menu } from 'antd';
import Sider from 'antd/es/layout/Sider';
import { signOut } from 'firebase/auth';
import { auth } from './firebase/firebaseConfig';
import TrendingVehicles from './Components/TrendingVehicles/TrendingVehicles';
import BankCharges from './Components/BankCharges/BankChargess';
import Blogs from './Components/Blogs/Blogs';
import secureLocalStorage from 'react-secure-storage';
import Affiliate from './Components/Affiliate/Affiliate';
import Booking from './Components/Booking/Booking';

function App () {
  const [ collapsed, setCollapsed ] = useState( true );
  function getItem ( label, key, icon, href ) {
    return {
      label,
      key,
      icon,
      href
    };
  }
  const items = [
    getItem( 'Make', '0', <FileAddOutlined />, "/" ),
    getItem( 'Editing', '1', <EditOutlined />, "/editing" ),
    getItem( 'Vendors', '2', <UsergroupAddOutlined />, "/vendors" ),
    getItem( 'Affiliate', '3', <UsergroupAddOutlined />, "/affiliate" ),
    getItem( 'Accessories', '4', <AppstoreAddOutlined />, "/accessories" ),
    getItem( 'Banking', '5', <BankOutlined />, "/banks" ),
    getItem( 'Banking Charges', '6', <BankOutlined />, "/banks-charges" ),
    getItem( 'Insurance Companies', '7', <InsuranceOutlined />, "/insurance-companies" ),
    getItem( 'Events', '8', <CalendarOutlined />, "/events" ),
    getItem( 'Documents', '9', <FilePdfOutlined />, "/documents" ),
    getItem( 'Suppliers', '10', <UsergroupAddOutlined />, "/suppliers" ),
    getItem( 'Religions', '11', <MenuOutlined />, "/religions" ),
    getItem( 'Aums Companies', '12', <BuildOutlined />, "/aums-companies" ),
    getItem( 'Trending Vehicles', '13', <FaMotorcycle />, "/trending-vehicles" ),
    getItem( 'Blogs', '14', <FileDoneOutlined />, "/blogs" ),
    getItem( 'New Booking', '15', <FileDoneOutlined />, "/booking" ),
    getItem( 'Log Out', '16', <LogoutOutlined />, "/" ),
  ];

  let login = secureLocalStorage.getItem( "login" );

  useEffect( () => {
    if ( secureLocalStorage.getItem( "login" ) ) {
      let time = new Date( secureLocalStorage.getItem( "loginTime" ) );
      let currentTime = new Date();
      if ( ( currentTime - time ) >= 180202002 ) {
        secureLocalStorage.removeItem( "login" );
        secureLocalStorage.removeItem( "loginTime" );
        signOut( auth );
        setTimeout( () => {
          window.location.reload();
        }, 800 );
      }
    }
  }, [ login ] );

  return (
    <BrowserRouter>
      <div className="App">
        <div className='sending-loader'>
          <div className="loader"></div>
        </div>
        <Layout
          style={{
            minHeight: '100vh',
          }}
        >
          <Sider collapsible collapsed={collapsed} onCollapse={( value ) => setCollapsed( value )}>
            <div className="demo-logo-vertical" />
            <Menu theme="dark" defaultSelectedKeys={[ '0' ]} mode="inline" items={items} onClick={event => {
              if ( event.key !== "16" ) {
                document.querySelectorAll( ".menu-link" )[ event.key ].click();
              } else {
                signOut( auth );
                window.location.reload();
              }
            }} />
            {items.map( ( element ) => (
              <Link className='menu-link' to={element.href} style={{ display: "none" }}>Click</Link>
            ) )}
          </Sider>
          <Layout>
            <Routes>
              <Route path='/login' element={<Login />} />
              <Route path='*' exact={true} element={<Make />} />
              <Route path='/' element={<Make />} />
              <Route path='/editing' element={<Editing />} />
              <Route path='/vendors' element={<Vendors />} />
              <Route path='/affiliate' element={<Affiliate />} />
              <Route path='/customers' element={<Customers />} />
              <Route path='/accessories' element={<Accessories />} />
              <Route path='/banks' element={<Banking />} />
              <Route path='/banks-charges' element={<BankCharges />} />
              <Route path='/suppliers' element={<Suppliers />} />
              <Route path='/insurance-companies' element={<InsuranceCompanies />} />
              <Route path='/events' element={<Events />} />
              <Route path='/documents' element={<Documents />} />
              <Route path='/religions' element={<ReligionSubReligion />} />
              <Route path='/aums-companies' element={<AumsCompanies />} />
              <Route path='/trending-vehicles' element={<TrendingVehicles />} />
              <Route path='/blogs' element={<Blogs />} />
              <Route path='/booking' element={<Booking />} />
            </Routes>
          </Layout>
        </Layout>
      </div>
    </BrowserRouter>
  );
}

export default App;
