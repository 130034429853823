import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { firestore, auth } from "../../firebase/firebaseConfig";
import { updateDoc, setDoc, getDocs, doc, collection, deleteDoc, onSnapshot } from '@firebase/firestore';
import { Button, Input, Select, message } from 'antd';

function BankCharges () {
    let [ addObject, setAddObject ] = useState( {} );
    let [ charges, setCharges ] = useState( [] );
    let [ banks, setBanks ] = useState( [] );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onload () {
        let makeDocs = await getDocs( collection( firestore, "banks" ) );
        let bankArray = [];
        makeDocs.forEach( ( element ) => {
            bankArray.push( {
                id: element.id,
                name: element.data().name
            } );
        } )
        setBanks( [ ...bankArray ] );
        document.querySelector( ".sending-loader" ).style.display = "none";
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'All Fields are compulsory to be filled.',
        } );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 6 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className='making-container'>
            {contextHolder}
            <form onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                if ( addObject.bank && addObject.type && addObject.chargeType ) {
                    if ( addObject.type === "Charge" ) {
                        await setDoc( doc( firestore, "banks/" + addObject.bank + "/charges", addObject.name ), {
                            name: addObject.name,
                            chargeOn: addObject.chargeOn,
                            type: addObject.type,
                            chargeType: addObject.chargeType,
                            amount: addObject.amount
                        } );
                    } else {
                        await setDoc( doc( firestore, "banks/" + addObject.bank + "/charges", addObject.name ), {
                            name: addObject.name,
                            chargeOn: addObject.chargeOn,
                            type: addObject.type,
                            chargeType: addObject.chargeType,
                            amounts: addObject.amounts
                        } );
                    }
                    setAddObject( {
                        bank: addObject.bank
                    } );
                } else {
                    error();
                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }} className="making-container-top-container">
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.bank ?? []}
                    placeholder="Select Bank"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            onSnapshot( collection( firestore, "banks/" + event + "/charges" ), ( snapshot ) => {
                                let chargesArray = [];
                                snapshot.forEach( ( element ) => {
                                    chargesArray.push( element.data() );
                                } )
                                setCharges( [ ...chargesArray ] );
                                setAddObject( { ...addObject, bank: event } );
                            } );
                        } else {
                            delete addObject.bank;
                            delete addObject.name;
                            delete addObject.type;
                            delete addObject.chargeType;
                            setAddObject( { ...addObject } );
                            setCharges( [] );
                        }
                    }}
                    options={banks.map( ( element ) => ( { label: element.name, value: element.id } ) )}
                />
                <Input value={addObject.name} placeholder="Enter Charge Name" style={{ width: "180px" }} onChange={event => {
                    setAddObject( { ...addObject, name: event.target.value } );
                }} required></Input>
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.chargeOn ?? []}
                    placeholder="Select Charge On"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            setAddObject( { ...addObject, chargeOn: event } );
                        } else {
                            delete addObject.chargeOn;
                            setAddObject( { ...addObject } );
                        }
                    }}
                    options={[
                        { label: "Loan", value: "Loan" },
                        { label: "Downpayment", value: "Downpayment" }
                    ]}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.type ?? []}
                    placeholder="Select Type"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            if ( event === "Table" ) {
                                addObject.type = event;
                                addObject.amounts = [ {
                                    range: "",
                                    first: "",
                                    second: "",
                                    third: "",
                                    fourth: "",
                                    fifth: ""
                                } ];
                                setAddObject( { ...addObject } );
                            } else {
                                setAddObject( { ...addObject, type: event } );
                            }
                        } else {
                            delete addObject.type;
                            setAddObject( { ...addObject } );
                        }
                    }}
                    options={[
                        { label: "Charge", value: "Charge" },
                        { label: "Table", value: "Table" }
                    ]}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={addObject.chargeType ?? []}
                    placeholder="Select Charge Type"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            setAddObject( { ...addObject, chargeType: event } );
                        } else {
                            delete addObject.chargeType;
                            setAddObject( { ...addObject } );
                        }
                    }}
                    options={[
                        { label: "Fixed", value: "Fixed" },
                        { label: "Percentage", value: "Percentage" }
                    ]}
                />
                {addObject.type === "Charge" && <Input value={addObject.amount} placeholder="Enter Amount" style={{ width: "180px" }} onChange={event => {
                    setAddObject( { ...addObject, amount: event.target.value } );
                }} required></Input>}
                {addObject.type === "Table" && <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                    {addObject.amounts?.map( ( element, index ) => (
                        <div style={{ display: "flex", alignItems: "center", gap: "1rem", flexWrap: "wrap" }}>
                            <Input value={element.range} placeholder="Enter Range" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].range = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            <Input value={element.first} placeholder="Enter 1st Year" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].first = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            <Input value={element.second} placeholder="Enter 2nd Year" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].second = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            <Input value={element.third} placeholder="Enter 3rd Year" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].third = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            <Input value={element.fourth} placeholder="Enter 4th Year" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].fourth = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            <Input value={element.fifth} placeholder="Enter 5th Year" style={{ width: "180px" }} onChange={event => {
                                addObject.amounts[ index ].fifth = event.target.value;
                                setAddObject( { ...addObject } );
                            }} required></Input>
                            {addObject.amounts?.length !== 1 && <Button onClick={event => {
                                let array = addObject.amounts;
                                array.splice( index, 1 );
                                setAddObject( { ...addObject, amounts: array } );
                            }} danger>Delete</Button>}
                        </div>
                    ) )}
                </div>}
                {addObject.type === "Table" && <Button onClick={event => {
                    let array = addObject.amounts;
                    array.push( {
                        range: "",
                        first: "",
                        second: "",
                        third: "",
                        fourth: "",
                        fifth: ""
                    } );
                    setAddObject( { ...addObject, amounts: array } );
                }}>Add Row</Button>}
                <Button htmlType='submit'>Add Charge</Button>
            </form>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Charges
                <Button onClick={event => {
                    document.querySelector( ".sending-loader" ).style.display = "flex";
                    charges.forEach( async ( element ) => {
                        if ( element.type && element.chargeType ) {
                            if ( element.amounts ) {
                                element.amounts = element.amounts.sort( ( a, b ) => a.range.split( "-" )[ 0 ] - b.range.split( "-" )[ 0 ] )
                            }
                            await updateDoc( doc( firestore, "banks/" + addObject.bank + "/charges", element.name ), element );
                        }
                    } )
                    messageApi.success( "The changes are saved." );
                    document.querySelector( ".sending-loader" ).style.display = "none";
                }}>Save Charges</Button>
            </div>
            <div className='all-make-entries-container'>
                {charges.map( ( element, index ) => (
                    <div style={{ alignItems: "center", width: "100%", justifyContent: "flex-start", flexWrap: "wrap" }} className='all-make-entry-container'>
                        <Input value={element.name} placeholder="Enter Charge Name" style={{ width: "180px" }} onChange={event => {
                            charges[ index ].name = event.target.value;
                            setCharges( [ ...charges ] );
                        }} required></Input>
                        <Select
                            showSearch
                            filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                            allowClear
                            value={element.chargeOn ?? []}
                            placeholder="Select Charge On"
                            style={{ width: "180px" }}
                            onChange={async event => {
                                if ( event ) {
                                    charges[ index ].chargeOn = event;
                                    setCharges( [ ...charges ] );
                                } else {
                                    delete charges[ index ].chargeOn;
                                    setCharges( [ ...charges ] );
                                }
                            }}
                            options={[
                                { label: "Loan", value: "Loan" },
                                { label: "Downpayment", value: "Downpayment" }
                            ]}
                        />
                        <Select
                            showSearch
                            filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                            allowClear
                            value={element.type ?? []}
                            placeholder="Select Type"
                            style={{ width: "180px" }}
                            onChange={async event => {
                                if ( event ) {
                                    if ( event === "Table" ) {
                                        charges[ index ].type = event;
                                        charges[ index ].amounts = [ {
                                            range: "",
                                            first: "",
                                            second: "",
                                            third: "",
                                            fourth: "",
                                            fifth: ""
                                        } ];
                                        setCharges( [ ...charges ] );
                                    } else {
                                        charges[ index ].type = event;
                                        setCharges( [ ...charges ] );
                                    }
                                } else {
                                    delete charges[ index ].type;
                                    setCharges( [ ...charges ] );
                                }
                            }}
                            options={[
                                { label: "Charge", value: "Charge" },
                                { label: "Table", value: "Table" }
                            ]}
                        />
                        <Select
                            showSearch
                            filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                            allowClear
                            value={element.chargeType ?? []}
                            placeholder="Select Charge Type"
                            style={{ width: "180px" }}
                            onChange={async event => {
                                if ( event ) {
                                    charges[ index ].chargeType = event;
                                    setCharges( [ ...charges ] );
                                } else {
                                    delete charges[ index ].chargeType;
                                    setCharges( [ ...charges ] );
                                }
                            }}
                            options={[
                                { label: "Fixed", value: "Fixed" },
                                { label: "Percentage", value: "Percentage" }
                            ]}
                        />
                        {element.type === "Charge" && <Input value={element.amount} placeholder="Enter Amount" style={{ width: "180px" }} onChange={event => {
                            charges[ index ].amount = event.target.value;
                            setCharges( [ ...charges ] );
                        }} required></Input>}
                        <Button onClick={async event => {
                            await deleteDoc( doc( firestore, "banks/" + addObject.bank + "/charges", element.name ) );
                        }} danger>Delete Charge</Button>
                        {element.type === "Table" && <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
                            {element.amounts?.map( ( element1, index1 ) => (
                                <div style={{ display: "flex", alignItems: "center", gap: "1rem" }}>
                                    <Input value={element1.range} placeholder="Enter Range" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].range = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    <Input value={element1.first} placeholder="Enter 1st Year" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].first = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    <Input value={element1.second} placeholder="Enter 2nd Year" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].second = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    <Input value={element1.third} placeholder="Enter 3rd Year" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].third = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    <Input value={element1.fourth} placeholder="Enter 4th Year" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].fourth = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    <Input value={element1.fifth} placeholder="Enter 5th Year" style={{ width: "180px" }} onChange={event => {
                                        charges[ index ].amounts[ index1 ].fifth = event.target.value;
                                        setCharges( [ ...charges ] );
                                    }} required></Input>
                                    {element.amounts?.length !== 1 && <Button onClick={event => {
                                        let array = charges[ index ].amounts;
                                        array.splice( index1, 1 );
                                        charges[ index ].amounts = array;
                                        setCharges( [ ...charges ] );
                                    }} danger>Delete</Button>}
                                </div>
                            ) )}
                        </div>}
                        {element.type === "Table" && <Button onClick={event => {
                            let array = element.amounts;
                            array.push( {
                                range: "",
                                first: "",
                                second: "",
                                third: "",
                                fourth: "",
                                fifth: ""
                            } );
                            charges[ index ].amounts = array;
                            setCharges( [ ...charges ] );
                        }}>Add Row</Button>}
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default BankCharges