import React, { useState, useEffect } from 'react';
import "./Banking.css";
import { firestore, auth } from '../../firebase/firebaseConfig';
import { collection, deleteDoc, doc, getDoc, updateDoc, addDoc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Input, Modal, Popconfirm, Select, Space, message } from 'antd';

function Banking () {
    let [ banksArray, setBanksArray ] = useState( [] );
    let [ selectedBank, setSelectedBank ] = useState( {} );
    let [ addDetails, setAddDetails ] = useState( {} );
    let [ schemes, setSchemes ] = useState( [] );
    let [ charges, setCharges ] = useState( [] );
    let [ coupons, setCoupons ] = useState( [] );
    let [ couponsModal, setCouponsModal ] = useState( false );
    const [ messageApi, contextHolder ] = message.useMessage();
    let [ percentage, setPercentage ] = useState( "100" );
    let [ description, setDescription ] = useState( "" );
    let [ name, setName ] = useState( "" );
    let [ limit, setLimit ] = useState( "Unlimited" );

    async function getInfo () {
        onSnapshot( collection( firestore, "banks" ), ( snapshot ) => {
            let banksRawArray = [];
            snapshot.forEach( async ( element ) => {
                banksRawArray.push( {
                    id: element.id,
                    data: element.data()
                } );
            } )
            setBanksArray( [ ...banksRawArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    const bankError = () => {
        messageApi.open( {
            type: 'error',
            content: 'The bank is not selected.',
        } );
    };

    useEffect( () => {
        document.querySelectorAll( ".ant-menu-item" )[ 5 ].click();
        document.querySelector( ".sending-loader" ).style.display = "flex";
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                getInfo();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='banking-container'>
            {contextHolder}
            <Modal
                title="List Of Coupons"
                open={couponsModal}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
                cancelButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
                onCancel={() => setCouponsModal( false )}
                width="80%"
            >
                <Space direction='vertical' style={{ width: "100%" }}>
                    <div className='coupons-list'>
                        <div className='coupons-list-head'>
                            <span>Name</span>
                            <span>Percentage</span>
                            <span>Description</span>
                            <span>Limit</span>
                            {/* <span>Used</span>
                            <span>Used By</span> */}
                        </div>
                        {coupons.map( ( element, index ) => (
                            <div className='coupons-list-item'>
                                <Input placeholder='Enter Id' value={element.name} onChange={( event ) => {
                                    coupons[ index ].name = event.target.value;
                                    setCoupons( [ ...coupons ] );
                                }}></Input>
                                <Input placeholder='Enter Percentage' value={element.percentage} onChange={( event ) => {
                                    if ( !isNaN( event.target.value ) ) {
                                        coupons[ index ].percentage = event.target.value;
                                        setCoupons( [ ...coupons ] );
                                    }
                                }} readOnly={element.used}></Input>
                                <Input placeholder='Enter Description' value={element.description} onChange={( event ) => {
                                    coupons[ index ].description = event.target.value;
                                    setCoupons( [ ...coupons ] );
                                }}></Input>
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    value={element.limit ?? []}
                                    placeholder="Select Limit"
                                    style={{ width: "100%" }}
                                    onChange={event => {
                                        coupons[ index ].limit = event;
                                        setCoupons( [ ...coupons ] );
                                    }}
                                    options={[
                                        { label: "Limited", value: "Limited" },
                                        { label: "Unlimited", value: "Unlimited" },
                                    ]}
                                />
                                <Popconfirm
                                    title="Do you want to delete the coupon ?"
                                    onConfirm={async () => {
                                        await deleteDoc( doc( firestore, "banks/" + selectedBank.id + "/coupons", element.id ) )
                                    }}
                                >
                                    <Button danger>Delete Coupon</Button>
                                </Popconfirm>
                                {/* <Input placeholder='Enter Used' value={element.used} readOnly></Input>
                                <Input placeholder='Enter Used By' value={element.usedBy ?? "Not Used"} readOnly></Input> */}
                            </div>
                        ) )}
                    </div>
                    <form onSubmit={( event ) => {
                        event.preventDefault();
                        let array = coupons;
                        array.push( {
                            name: name,
                            percentage: percentage,
                            description: description,
                            limit: limit,
                            // used: false
                        } )
                        setCoupons( [ ...array ] );
                        setPercentage( "100" );
                        setName( "" );
                        setDescription( "" );
                        setLimit( "Unlimited" );
                    }} className='coupon-form'>
                        <Input placeholder='Enter Name' value={name} onChange={( event ) => {
                            setName( event.target.value );
                        }} required></Input>
                        <Input placeholder='Enter Percentage' value={percentage} onChange={( event ) => {
                            if ( !isNaN( event.target.value ) ) {
                                setPercentage( event.target.value );
                            }
                        }} required></Input>
                        <Input placeholder='Enter Description' value={description} onChange={( event ) => {
                            setDescription( event.target.value );
                        }} required></Input>
                        <Select
                            showSearch
                            filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                            value={limit ?? []}
                            placeholder="Select Limit"
                            style={{ width: "100%" }}
                            onChange={event => {
                                setLimit( event )
                            }}
                            options={[
                                { label: "Limited", value: "Limited" },
                                { label: "Unlimited", value: "Unlimited" },
                            ]}
                        />
                        <Button htmlType='submit'>Add Coupon</Button>
                        <Button onClick={() => {
                            messageApi.loading( "Saving The Coupons...", 0 );
                            coupons.forEach( async ( element ) => {
                                if ( element.id ) {
                                    await updateDoc( doc( firestore, "banks/" + selectedBank.id + "/coupons", element.id ), element );
                                } else {
                                    await addDoc( collection( firestore, "banks/" + selectedBank.id + "/coupons" ), element );
                                }
                            } )
                            messageApi.destroy();
                            messageApi.success( "Saved The Coupons." );
                        }}>Save Coupons</Button>
                    </form>
                </Space>
            </Modal>
            <form onSubmit={async event => {
                event.preventDefault();
                if ( Object.keys( selectedBank ).length !== 0 ) {
                    document.querySelector( ".sending-loader" ).style.display = "flex";
                    await addDoc( collection( firestore, "banks/" + selectedBank.id + "/schemes" ), addDetails );
                    setAddDetails( {} );
                    event.target.reset();
                    document.querySelector( ".sending-loader" ).style.display = "none";
                } else {
                    bankError();
                }
            }} className='scheme-entry-container'>
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={selectedBank?.data?.name}
                    placeholder="Select a Bank"
                    style={{ width: "180px" }}
                    onChange={async event => {
                        if ( event ) {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            let filteredArray = banksArray.filter( ( element ) => element.id === event );
                            setSelectedBank( { ...filteredArray[ 0 ] } );
                            onSnapshot( collection( firestore, "banks/" + event + "/schemes" ), ( snapshot ) => {
                                let schemesArray = [];
                                snapshot.forEach( ( element ) => {
                                    schemesArray.push( {
                                        id: element.id,
                                        data: element.data()
                                    } )
                                } );
                                setSchemes( [ ...schemesArray ] );
                                document.querySelector( ".sending-loader" ).style.display = "none";
                            } )
                            onSnapshot( collection( firestore, "banks/" + event + "/charges" ), ( snapshot ) => {
                                let chargesArray = [];
                                snapshot.forEach( async ( element ) => {
                                    chargesArray.push( element.id );
                                } )
                                setCharges( [ ...chargesArray ] );
                            } );
                            onSnapshot( collection( firestore, "banks/" + event + "/coupons" ), ( snapshot ) => {
                                let array = [];
                                snapshot.forEach( async ( element ) => {
                                    array.push( { id: element.id, ...element.data() } );
                                } )
                                setCoupons( [ ...array ] );
                            } );
                        } else {
                            setSelectedBank( {} );
                        }
                    }}
                    options={banksArray.map( ( element ) => ( { label: element.data.name, value: element.id } ) )}
                />
                <div className='scheme-entry-inner-container'>
                    <input type="text" placeholder='Scheme Name' name='scheme_name' onChange={event => {
                        setAddDetails( { ...addDetails, scheme: event.target.value } )
                    }} style={{ width: "100%" }} required />
                    <input type="text" placeholder='Tenure' name='tenure' onChange={event => {
                        setAddDetails( { ...addDetails, tenure: event.target.value } )
                    }} required />
                    <input type="text" placeholder='Rate' name='rate' value={addDetails.rate ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setAddDetails( { ...addDetails, rate: event.target.value } )
                        }
                    }} required />
                    <input type="text" placeholder='Show Rate' name='show_rate' value={addDetails.show_rate ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setAddDetails( { ...addDetails, show_rate: event.target.value } )
                        }
                    }} required />
                    <input type="tel" placeholder='Advance EMI' name='advance-emi' value={addDetails.advanceEMI ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setAddDetails( { ...addDetails, advanceEMI: event.target.value } )
                        }
                    }} required />
                    <input type="tel" placeholder='Max Loan Percent' name='advance-emi' value={addDetails.maxLoanPercent ?? ""} onChange={event => {
                        setAddDetails( { ...addDetails, maxLoanPercent: event.target.value } );
                    }} required />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={addDetails.schemeType ?? []}
                        placeholder="Select Scheme Type"
                        style={{ width: "180px" }}
                        onChange={event => {
                            setAddDetails( { ...addDetails, schemeType: event } );
                        }}
                        options={[
                            { label: "Flat", value: "Flat" },
                            { label: "Reducing", value: "Reducing" },
                        ]}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={addDetails.charges ?? []}
                        options={charges.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            if ( newValue.length === 0 ) {
                                setAddDetails( { ...addDetails, charges: [] } );
                            } else {
                                setAddDetails( { ...addDetails, charges: newValue } );
                            }
                        }}
                        placeholder='Select Charges'
                        maxTagCount='responsive'
                    />
                </div>
                <Button htmlType="submit">Add Scheme</Button>
            </form>
            {
                Object.keys( selectedBank.data ?? {} ).length !== 0 && <div className='banking-container-bank-container'>
                    <div className='bank-container-header all-make-city-container'>
                        <span>
                            <h4 style={{ margin: "0" }}>{selectedBank.data?.name}</h4>
                            <input type="tel" className='customer-care-mobile' value={selectedBank.data?.customer_care_number} onChange={event => {
                                selectedBank.data.customer_care_number = event.target.value;
                                setSelectedBank( { ...selectedBank } );
                            }} placeholder='Customer Care Mobile' />
                            <input type="email" className='customer-care-email' value={selectedBank.data?.customer_care_email} onChange={event => {
                                selectedBank.data.customer_care_email = event.target.value;
                                setSelectedBank( { ...selectedBank } );
                            }} placeholder='Customer Care Email' />
                        </span>
                        <div style={{ display: "flex", gap: "1rem" }}>
                            <Button onClick={() => {
                                setCouponsModal( true );
                            }}>Coupons</Button>
                            <Button onClick={async event => {
                                document.querySelector( ".sending-loader" ).style.display = "flex";
                                await updateDoc( doc( firestore, "banks", selectedBank.id ), selectedBank.data );
                                schemes.forEach( async ( element ) => {
                                    await updateDoc( doc( firestore, "banks/" + selectedBank.id + "/schemes", element.id ), element.data );
                                } )
                                document.querySelector( ".sending-loader" ).style.display = "none";
                            }}>Save Bank</Button>
                        </div>
                    </div>
                    <div className='schemes-container'>
                        {schemes.sort( ( a, b ) => {
                            var textA = a.data.scheme.toUpperCase();
                            var textB = b.data.scheme.toUpperCase();
                            return ( textA < textB ) ? -1 : ( textA > textB ) ? 1 : 0;
                        } ).map( ( element, index ) => (
                            <div className='bank-scheme-container'>
                                <div>
                                    <input type="text" placeholder='Scheme Name' name='scheme_name' value={element.data.scheme} onChange={event => {
                                        schemes[ index ].data[ "scheme" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required style={{ width: "100%" }} />
                                    <input type="text" placeholder='Tenure' name='tenure' value={element.data.tenure} onChange={event => {
                                        schemes[ index ].data[ "tenure" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required />
                                    <input type="text" placeholder='Rate' name='rate' value={element.data.rate} onChange={event => {
                                        schemes[ index ].data[ "rate" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required />
                                    <input type="text" placeholder='Show Rate' name='show_rate' value={element.data.show_rate} onChange={event => {
                                        schemes[ index ].data[ "show_rate" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required />
                                    <input type="tel" placeholder='Advance EMI' name='advance-emi' value={element.data.advanceEMI} onChange={event => {
                                        schemes[ index ].data[ "advanceEMI" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required />
                                    <input type="tel" placeholder='Max Loan Percent' name='max-loan-percent' value={element.data.maxLoanPercent} onChange={event => {
                                        schemes[ index ].data[ "maxLoanPercent" ] = event.target.value;
                                        setSchemes( [ ...schemes ] );
                                    }} required />
                                    <Select
                                        showSearch
                                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                        value={element.data.schemeType ?? []}
                                        placeholder="Select Scheme Type"
                                        style={{ width: "180px" }}
                                        onChange={event => {
                                            schemes[ index ].data.schemeType = event;
                                            setSchemes( [ ...schemes ] );
                                        }}
                                        options={[
                                            { label: "Flat", value: "Flat" },
                                            { label: "Reducing", value: "Reducing" },
                                        ]}
                                    />
                                    <Select
                                        showSearch
                                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                        mode='multiple'
                                        style={{
                                            width: '180px'
                                        }}
                                        value={element.data.charges ?? []}
                                        options={charges.map( ( element ) => ( { label: element, value: element } ) )}
                                        onChange={( newValue ) => {
                                            if ( newValue.length === 0 ) {
                                                schemes[ index ].data[ "charges" ] = [];
                                                setSchemes( [ ...schemes ] );
                                            } else {
                                                schemes[ index ].data[ "charges" ] = newValue;
                                                setSchemes( [ ...schemes ] );
                                            }
                                        }}
                                        placeholder='Select Charges'
                                        maxTagCount='responsive'
                                    />
                                </div>
                                <Button onClick={async event => {
                                    document.querySelector( ".sending-loader" ).style.display = "flex";
                                    schemes[ index ].data.models?.forEach( async ( element1 ) => {
                                        let modelDoc = await getDoc( doc( firestore, "Make/" + element1.make + "/Models", element1.model ) );
                                        let newArray = modelDoc.data().schemes?.filter( ( element2 ) => element2 !== element.id );
                                        await updateDoc( doc( firestore, "Make/" + element1.make + "/Models", element1.model ), {
                                            schemes: newArray
                                        } )
                                    } )
                                    await deleteDoc( doc( firestore, "banks/" + selectedBank.id + "/schemes", element.id ) );
                                    document.querySelector( ".sending-loader" ).style.display = "none";
                                }}>Delete Scheme</Button>
                            </div>
                        ) )}
                    </div>
                </div>
            }
        </div >
    )
}

export default Banking