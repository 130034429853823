import React, { useState, useEffect, useRef } from 'react';
import "./Editing.css";
import { firestore, storage, auth } from '../../firebase/firebaseConfig';
import { collection, getDocs, doc, setDoc, getDoc, updateDoc, query, where, onSnapshot, deleteDoc } from '@firebase/firestore';
import { ref, uploadBytes } from "firebase/storage";
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Collapse, ColorPicker, Modal, Popconfirm, Select, Upload, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import JoditEditor from 'jodit-react';
import ReactCrop from 'react-image-crop';
import 'react-image-crop/dist/ReactCrop.css';

function Editing () {
    const [ messageApi, contextHolder ] = message.useMessage();
    let [ companyArray, setCompanyArray ] = useState( [] );
    let [ modelsArray, setModelsArray ] = useState( [] );
    let [ variantArray, setVariantArray ] = useState( [ "All" ] );
    let [ standardAccessoryArray, setStandardAccessoryArray ] = useState( [] );
    let [ standardAccessories, setStandardAccessories ] = useState( [] );
    let [ additionalAccessoryArray, setAdditionalAccessoryArray ] = useState( [] );
    let [ additionalAccessories, setAdditionalAccessories ] = useState( [] );
    let [ specifications, setSpecifications ] = useState( {} );
    let [ editDetails, setEditDetails ] = useState( {} );
    let [ timestampDisplay, setTimestampDisplay ] = useState( "" );
    let [ displayData, setDisplayData ] = useState( false );
    let [ colorObject, setColorObject ] = useState( {
        variant: "All"
    } );
    let [ variantName, setVariantName ] = useState( "" );
    let [ petrolModal, setPetrolModal ] = useState( false );
    let [ confirmLoading, setConfirmLoading ] = useState( false );
    let [ imagesModal, setImagesModal ] = useState( false );
    let [ editImagesModal, setEditImagesModal ] = useState( false );
    const [ previewOpen, setPreviewOpen ] = useState( false );
    const [ previewImage, setPreviewImage ] = useState( '' );
    const [ previewTitle, setPreviewTitle ] = useState( '' );
    const [ fileList, setFileList ] = useState( [] );
    const [ editModalObject, setEditModalObject ] = useState( {} );
    const [ editFileList, setEditFileList ] = useState( [] );
    const [ schemes, setSchemes ] = useState( [] );
    const [ selectedSchemes, setSelectedSchemes ] = useState( [] );
    const [ specificationsDetails, setSpecificationsDetails ] = useState( {} );
    const [ uploadModal, setUploadModal ] = useState( false );
    const [ uploadObject, setUploadObject ] = useState( {
        image: null,
        croppedImage: null,
        crop: { x: 0, y: 0, width: 50, height: 50, aspect: 1.5 },
        imageName: "",
    } );
    const imgRef = useRef( null );

    const items = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>List Of Charges</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => editModel( event )}>Save Changes</Button>}
            </div>,
            children: <div className='making-container-top-container' style={{ flexDirection: "column", alignItems: "flex-start", width: "100%", maxWidth: "100%", borderRadius: "0 0 0.75rem 0.75rem" }}>
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    mode='multiple'
                    style={{
                        width: '100%',
                    }}
                    value={selectedSchemes.map( ( element ) => JSON.stringify( element ) ) ?? []}
                    options={schemes.map( ( element ) => ( { label: element.bank + " - " + element.scheme, value: JSON.stringify( element ) } ) )}
                    onChange={( newValue ) => {
                        let array = newValue.map( ( element ) => JSON.parse( element ) );
                        setSelectedSchemes( [ ...array ] );
                    }}
                    placeholder='Select Schemes'
                    maxTagCount='responsive'
                />
                <div style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "1rem",
                    width: "100%",
                    maxHeight: "300px",
                    overflowY: "scroll",
                    flexWrap: "nowrap"
                }}>
                    {selectedSchemes.map( ( element, index ) => (
                        <div style={{
                            display: "flex",
                            gap: "1rem"
                        }}>
                            <input type="text" value={element.bank + " - " + element.scheme} style={{ width: "100%" }} readOnly />
                        </div>
                    ) )}
                </div>
            </div>,
        },
    ];

    const mumbai = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Mumbai</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => editModel( event )}>Save Changes</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( editDetails.cities && editDetails.cities[ "Mumbai" ] ? editDetails.cities[ "Mumbai" ] : {} ).map( ( variant, index ) => (
                        editDetails.cities[ "Mumbai" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Mumbai"} readOnly />
                                <input type="text" value={editDetails.make} readOnly />
                                <input type="text" value={editDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Mumbai" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Mumbai" ][ variant ].accessories_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Mumbai" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Mumbai" ][ variant ].additional_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( editDetails.cities ).forEach( ( element ) => {
                                        editDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setEditDetails( { ...editDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        editDetails.cities[ "Mumbai" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setEditDetails( { ...editDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        console.log( index1 );
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    // setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                    //     name: colorImage,
                                    //     url: ( "https://cdn.aapli.in/Make%2F" + editDetails.make + "%2FModels%2F" + editDetails.model + "%2FCities%2FMumbai%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    // } ) ) )
                                    // editModalObject.variant = variant;
                                    // editModalObject.color = index1;
                                    // setEditModalObject( { ...editModalObject } );
                                    // setEditImagesModal( true )
                                    handleEditImagesClick( "Mumbai", variant, index1 )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...editDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Popconfirm
                                    title="Delete the Color"
                                    description="Are you sure to delete this color?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Color..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + color.name;
                                            editDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + variant + "/Colors", color.name ) );
                                            await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Color." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Color</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Delete the Variant"
                                    description="Are you sure to delete this variant?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Variant..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors.forEach( async ( element1 ) => {
                                                let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + element1.name;
                                                await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            } )
                                            delete editDetails.cities[ element ][ variant ];
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants", variant ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        variantArray.splice( index, 1 );
                                        setVariantArray( [ ...variantArray ] );
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Variant." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Variant</Button>
                                </Popconfirm>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const newDelhi = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>New Delhi</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => editModel( event )}>Save Changes</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( editDetails.cities && editDetails.cities[ "New Delhi" ] ? editDetails.cities[ "New Delhi" ] : {} ).map( ( variant, index ) => (
                        editDetails.cities[ "New Delhi" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"New Delhi"} readOnly />
                                <input type="text" value={editDetails.make} readOnly />
                                <input type="text" value={editDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "New Delhi" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "New Delhi" ][ variant ].accessories_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "New Delhi" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "New Delhi" ][ variant ].additional_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( editDetails.cities ).forEach( ( element ) => {
                                        editDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setEditDetails( { ...editDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        editDetails.cities[ "New Delhi" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setEditDetails( { ...editDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ "New Delhi" ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            editDetails.cities[ "New Delhi" ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://cdn.aapli.in/Make%2F" + editDetails.make + "%2FModels%2F" + editDetails.model + "%2FCities%2FNew Delhi%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...editDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Popconfirm
                                    title="Delete the Color"
                                    description="Are you sure to delete this color?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Color..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + color.name;
                                            editDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + variant + "/Colors", color.name ) );
                                            await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Color." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Color</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Delete the Variant"
                                    description="Are you sure to delete this variant?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Variant..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors.forEach( async ( element1 ) => {
                                                let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + element1.name;
                                                await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            } )
                                            delete editDetails.cities[ element ][ variant ];
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants", variant ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        variantArray.splice( index, 1 );
                                        setVariantArray( [ ...variantArray ] );
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Variant." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Variant</Button>
                                </Popconfirm>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const chennai = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Chennai</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => editModel( event )}>Save Changes</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( editDetails.cities && editDetails.cities[ "Chennai" ] ? editDetails.cities[ "Chennai" ] : {} ).map( ( variant, index ) => (
                        editDetails.cities[ "Chennai" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Chennai"} readOnly />
                                <input type="text" value={editDetails.make} readOnly />
                                <input type="text" value={editDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Chennai" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Chennai" ][ variant ].accessories_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Chennai" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Chennai" ][ variant ].additional_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( editDetails.cities ).forEach( ( element ) => {
                                        editDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setEditDetails( { ...editDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        editDetails.cities[ "Chennai" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setEditDetails( { ...editDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ "Chennai" ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            editDetails.cities[ "Chennai" ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://cdn.aapli.in/Make%2F" + editDetails.make + "%2FModels%2F" + editDetails.model + "%2FCities%2FChennai%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...editDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Popconfirm
                                    title="Delete the Color"
                                    description="Are you sure to delete this color?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Color..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + color.name;
                                            editDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + variant + "/Colors", color.name ) );
                                            await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Color." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Color</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Delete the Variant"
                                    description="Are you sure to delete this variant?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Variant..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors.forEach( async ( element1 ) => {
                                                let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + element1.name;
                                                await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            } )
                                            delete editDetails.cities[ element ][ variant ];
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants", variant ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        variantArray.splice( index, 1 );
                                        setVariantArray( [ ...variantArray ] );
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Variant." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Variant</Button>
                                </Popconfirm>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    const kolkata = [
        {
            key: '1',
            label: <div style={{ width: "100%", display: "flex", justifyContent: "space-between", alignItems: "center" }}>
                <span>Kolkata</span>
                {variantArray.length > 1 && <Button style={{ color: "black", fontWeight: "600" }} onClick={event => editModel( event )}>Save Changes</Button>}
            </div>,
            children: <div className='all-make-entries-container' style={{ width: "100%", maxWidth: '100%', maxHeight: '500px', backgroundColor: "black" }}>
                {
                    Object.keys( editDetails.cities && editDetails.cities[ "Kolkata" ] ? editDetails.cities[ "Kolkata" ] : {} ).map( ( variant, index ) => (
                        editDetails.cities[ "Kolkata" ][ variant ].colors.map( ( color, index1 ) => (
                            <div className='all-make-entry-container'>
                                <input type="text" value={"Kolkata"} readOnly />
                                <input type="text" value={editDetails.make} readOnly />
                                <input type="text" value={editDetails.model} readOnly />
                                <input type="text" value={variant} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Kolkata" ][ variant ].accessories_array}
                                    options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Kolkata" ][ variant ].accessories_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Standard Accessories'
                                    maxTagCount='responsive'
                                />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    allowClear
                                    mode='multiple'
                                    style={{
                                        width: '150px',
                                    }}
                                    value={editDetails.cities[ "Kolkata" ][ variant ].additional_array}
                                    options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                                    onChange={( newValue ) => {
                                        editDetails.cities[ "Kolkata" ][ variant ].additional_array = newValue;
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Additional Accessories'
                                    maxTagCount='responsive'
                                />
                                <input type="text" value={color.name} readOnly />
                                <Select
                                    showSearch
                                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '150px',
                                    }}
                                    value={color.status}
                                    options={[
                                        { label: "Active", value: "Active" },
                                        { label: "Out Of Stock", value: "Out Of Stock" },
                                        { label: "Discontinued", value: "Discontinued" },
                                    ]}
                                    onChange={( newValue ) => {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors[ index1 ].status = newValue;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }}
                                    placeholder='Select Status'
                                />
                                <ColorPicker showText format='hex' value={color.color ?? ""} onChange={event => {
                                    Object.keys( editDetails.cities ).forEach( ( element ) => {
                                        editDetails.cities[ element ][ variant ].colors[ index1 ].color = event.toHexString();
                                    } )
                                    setEditDetails( { ...editDetails } );
                                }} />
                                <input type="text" value={color.price} onChange={event => {
                                    if ( event.target.value !== "" ) {
                                        editDetails.cities[ "Kolkata" ][ variant ].colors[ index1 ].price = event.target.value;
                                        setEditDetails( { ...editDetails } );
                                    } else {
                                        error7();
                                    }
                                }} />
                                <Button onClick={event => document.querySelector( ".edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" ) ).click()}>{color.imageName}</Button>
                                <input type="file" className={"edit-make-image-upload-" + variant.toLowerCase().replaceAll( " ", "_" ) + "_" + color.name.toLowerCase().replaceAll( " ", "_" )} style={{ display: "none" }} onChange={event => {
                                    if ( event.target.files[ 0 ] ) {
                                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                                            editDetails.cities[ "Kolkata" ][ variant ].colors[ index1 ].image = event.target.files[ 0 ];
                                            editDetails.cities[ "Kolkata" ][ variant ].colors[ index1 ].imageName = event.target.files[ 0 ].name;
                                        } )
                                        setEditDetails( { ...editDetails } );
                                    }
                                }} />
                                <Button onClick={event => {
                                    setEditFileList( color.imageNameArray?.map( ( colorImage ) => ( {
                                        name: colorImage,
                                        url: ( "https://cdn.aapli.in/Make%2F" + editDetails.make + "%2FModels%2F" + editDetails.model + "%2FCities%2FKolkata%2FVariants%2F" + variant + "%2FColors%2F" + color.name + "%2F" + colorImage + "" ).replaceAll( " ", "%20" ).replaceAll( "+", "%2B" ).replaceAll( "&amp;", "%26" ),
                                    } ) ) )
                                    editModalObject.variant = variant;
                                    editModalObject.color = index1;
                                    setEditModalObject( { ...editModalObject } );
                                    setEditImagesModal( true )
                                }}>
                                    Edit Images
                                </Button>
                                <Button onClick={event => {
                                    setSpecifications( { ...editDetails.cities[ "Mumbai" ][ variant ].specifications ?? {} } );
                                    let object = {
                                        variant: variant,
                                        type: "Edit"
                                    }
                                    setSpecificationsDetails( { ...object } )
                                    setPetrolModal( true );
                                }}>Edit Specifications</Button>
                                <Popconfirm
                                    title="Delete the Color"
                                    description="Are you sure to delete this color?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Color..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + color.name;
                                            editDetails.cities[ element ][ variant ].colors.splice( index1, 1 );
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + variant + "/Colors", color.name ) );
                                            await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Color." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Color</Button>
                                </Popconfirm>
                                <Popconfirm
                                    title="Delete the Variant"
                                    description="Are you sure to delete this variant?"
                                    onConfirm={event => {
                                        messageApi.loading( "Deleting the Variant..." );
                                        Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                            editDetails.cities[ element ][ variant ].colors.forEach( async ( element1 ) => {
                                                let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + element1.name;
                                                await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                            } )
                                            delete editDetails.cities[ element ][ variant ];
                                            await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants", variant ) );
                                            setEditDetails( { ...editDetails } );
                                        } )
                                        variantArray.splice( index, 1 );
                                        setVariantArray( [ ...variantArray ] );
                                        messageApi.destroy();
                                        messageApi.success( "Deleted the Variant." );
                                    }}
                                    okText="Yes"
                                    cancelText="No"
                                >
                                    <Button danger>Delete Variant</Button>
                                </Popconfirm>
                            </div>
                        ) )
                    ) )}
            </div>
        },
    ];

    async function getInfo () {

        const querySnapshot1 = await getDocs( collection( firestore, "Make" ) );
        let companyRawArray = [];

        querySnapshot1.forEach( ( element1 ) => {
            companyRawArray.push( element1.id );
        } )
        setCompanyArray( companyRawArray );

        const querySnapshot2 = await getDocs( query( collection( firestore, "Accessories" ), where( "category", "==", "standard" ) ) );
        const querySnapshot21 = await getDocs( query( collection( firestore, "Accessories" ), where( "category", "==", "additional" ) ) );
        let StandardAccessoriesRawArray = [];
        let AdditionalAccessoriesRawArray = [];

        querySnapshot2.forEach( ( element ) => {
            StandardAccessoriesRawArray.push( element.data().name );
        } )
        querySnapshot21.forEach( ( element ) => {
            AdditionalAccessoriesRawArray.push( element.data().name );
        } )
        setStandardAccessoryArray( [ ...StandardAccessoriesRawArray ] );
        setStandardAccessories( [ ...StandardAccessoriesRawArray ] );
        setAdditionalAccessories( [ ...AdditionalAccessoriesRawArray ] );
        setAdditionalAccessoryArray( [ ...AdditionalAccessoriesRawArray ] );

        onSnapshot( collection( firestore, "banks" ), ( snapshot ) => {
            let schemesArray = [];
            snapshot.forEach( async ( element ) => {
                onSnapshot( collection( firestore, "banks/" + element.id + "/schemes" ), ( snapshot ) => {
                    snapshot.forEach( ( element1 ) => {
                        schemesArray.push( {
                            id: element1.id,
                            data: element1.data(),
                            scheme: element1.data().scheme,
                            bank: element.data().name,
                            bankId: element.id
                        } )
                    } );
                    setSchemes( [ ...schemesArray ] );
                    document.querySelector( ".sending-loader" ).style.display = "none";
                } )
            } )
        } );
    }

    async function editModel ( event ) {
        event.preventDefault();
        document.querySelector( ".sending-loader" ).style.display = "flex";
        messageApi.loading( "Saving All Data...", 0 );
        await updateDoc( doc( firestore, "Make/" + editDetails.make + "/Models/", editDetails.model ), {
            "image": editDetails.modelImageName,
            "thrust": editDetails.thrust ?? "",
            "youtube_link": editDetails.modelYoutubeLink ? editDetails.modelYoutubeLink : "",
            "insurance_discount": parseFloat( editDetails.insuranceDiscount ).toFixed( 2 ),
            "payout_percentage": parseFloat( editDetails.payoutPercentage ).toFixed( 2 ),
            "payout_on": editDetails.payoutOn ?? "",
            "timestamp": new Date(),
            schemes: selectedSchemes?.map( ( element ) => ( {
                id: element.id
            } ) )
        } );

        selectedSchemes?.forEach( async ( element ) => {
            let object = element;
            let array = new Set();
            object.data.models ?? [].forEach( ( element1 ) => {
                array.add( JSON.stringify( element1 ) );
            } );
            array.add( JSON.stringify( {
                make: editDetails.make,
                model: editDetails.model
            } ) )
            await updateDoc( doc( firestore, "banks/" + object.bankId + "/schemes", object.id ), {
                models: Array.from( array ).map( ( element1 ) => JSON.parse( element1 ) )
            } )
        } )

        if ( editDetails.modelImage ) {
            const storageRef1 = ref( storage, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/" + editDetails.modelImageName );
            await uploadBytes( storageRef1, editDetails.modelImage ).then( ( snapshot ) => {
                console.log( 'Uploaded a blob or file!' );
            } );
        }

        Object.keys( editDetails.cities ? editDetails.cities : {} ).forEach( async ( element ) => {
            Object.keys( editDetails.cities[ element ] ? editDetails.cities[ element ] : {} ).forEach( async ( element1 ) => {
                console.log( editDetails.cities[ element ][ element1 ].accessories_array );
                await setDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/", element1 ), {
                    "accessories_array": editDetails.cities[ element ][ element1 ].accessories_array ?? [],
                    "additional_array": editDetails.cities[ element ][ element1 ].additional_array ?? [],
                    "specifications": JSON.stringify( editDetails.cities[ element ][ element1 ].specifications ),
                } );
                editDetails.cities[ element ][ element1 ].colors.forEach( async ( element2 ) => {
                    await setDoc( doc( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/", element2.name ), {
                        color: element2.color ?? "",
                        "price": parseInt( element2.price ),
                        "image": element2.imageName,
                        imageArray: element2.imageNameArray ?? [],
                        status: element2.status
                    } );

                    await setDoc( doc( firestore, "aapli_global/aapli_search/search_items", editDetails.make + " " + editDetails.model + " " + element1 + " " + element2.name ), {
                        name: editDetails.make + " " + editDetails.model + " " + element1 + " " + element2.name,
                        path: "/" + editDetails.make + "/" + editDetails.model + "/" + element1 + "/" + element2.name
                    } )

                    if ( element2.image ) {
                        const storageRef = ref( storage, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/" + element2.imageName );
                        await uploadBytes( storageRef, element2.image ).then( ( snapshot ) => {
                            console.log( 'Uploaded a blob or file!' );
                        } );
                    }
                    if ( element2.imageArray?.length !== 0 ) {
                        element2.imageArray?.forEach( async ( image ) => {
                            if ( image ) {
                                const storageRef = ref( storage, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + element + "/Variants/" + element1 + "/Colors/" + element2.name + "/" + image.name );
                                await uploadBytes( storageRef, image ).then( ( snapshot ) => {
                                    console.log( 'Uploaded a blob or file!' );
                                } );
                            }
                        } )
                    }
                } )
            } )
        } )
        document.querySelector( ".sending-loader" ).style.display = "none";
        messageApi.destroy();
        messageApi.success( "All Data saved successfully." );
    }

    const getBase64 = ( file ) =>
        new Promise( ( resolve, reject ) => {
            const reader = new FileReader();
            reader.readAsDataURL( file );
            reader.onload = () => resolve( reader.result );
            reader.onerror = ( error ) => reject( error );
        } );

    const handleCancel = () => setPreviewOpen( false );
    const handlePreview = async ( file ) => {
        if ( !file.url && !file.preview ) {
            file.preview = await getBase64( file.originFileObj );
        }
        setPreviewImage( file.url || file.preview );
        setPreviewOpen( true );
        setPreviewTitle( file.name || file.url.substring( file.url.lastIndexOf( '/' ) + 1 ) );
    };
    const handleChange = ( { fileList: newFileList } ) => setFileList( newFileList );
    // const handleEditChange = ( { fileList: newFileList } ) => {
    //     Object.keys( editDetails.cities ).forEach( ( element ) => {
    //         editDetails.cities[ element ][ editModalObject.variant ].colors[ editModalObject.color ].imageArray = newFileList.map( ( element ) => element.originFileObj );
    //         editDetails.cities[ element ][ editModalObject.variant ].colors[ editModalObject.color ].imageNameArray = newFileList.map( ( element ) => element.name );
    //     } )
    //     setEditFileList( newFileList );
    //     setEditDetails( { ...editDetails } );
    // };
    const uploadButton = (
        <div>
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </div>
    );

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select Company to Proceed',
        } );
    };

    const error1 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to enter a name for the variant to add.',
        } );
    };

    const error4 = () => {
        messageApi.open( {
            type: 'error',
            content: 'Select Payout On Value before proceeding.',
        } );
    };

    const error6 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to upload a color image to proceed.',
        } );
    };

    const error8 = () => {
        messageApi.open( {
            type: 'error',
            content: "Insurance Discount should not be more than 100.",
        } );
    };

    const error7 = () => {
        messageApi.open( {
            type: 'error',
            content: "The price can't be zero. You need to delete the color.",
        } );
    };

    const error9 = () => {
        messageApi.open( {
            type: 'error',
            content: "Payout Percentage should not be more than 100.",
        } );
    };

    const element = () => {
        return <Button style={{ color: "black", fontWeight: "600" }}>Show Charges</Button>
    }

    const element1 = () => {
        return <Button style={{ color: "black", fontWeight: "600", fontSize: "14px" }}>Show Prices</Button>
    }

    const onCropComplete = async ( crop ) => {
        if ( imgRef.current && crop.width && crop.height ) {
            const blob = await getCroppedImage( imgRef.current, crop );
            setUploadObject( { ...uploadObject, croppedImage: blob } );
            setUploadObject( ( prev ) => ( { ...prev, croppedImage: blob } ) );
        }
    };

    const getCroppedImage = ( image, crop ) => {
        console.log(image)
        return new Promise( ( resolve ) => {
            const canvas = document.createElement( "canvas" );
            const scaleX = image.naturalWidth / image.width;
            const scaleY = image.naturalHeight / image.height;
            canvas.width = 525;
            canvas.height = 350;
            const ctx = canvas.getContext( "2d" );

            ctx.drawImage(
                image,
                crop.x * scaleX,
                crop.y * scaleY,
                525 * scaleX,
                350 * scaleY,
                0,
                0,
                525,
                350
            );

            canvas.toBlob( ( blob ) => {
                resolve( blob );
            }, "image/png" );
        } );
    };

    // Handle file selection and open crop modal
    const handleEditChange = ( { fileList: newFileList } ) => {
        const selectedFile = newFileList[ newFileList.length - 1 ];
        if ( selectedFile ) {
            const reader = new FileReader();
            reader.onload = () => {
                setUploadObject( {
                    image: reader.result,
                    croppedImage: null,
                    crop: { x: 0, y: 0, width: 50, height: 50, aspect: 1.5 },
                    imageName: selectedFile.name,
                } );
                setUploadModal( true );
            };
            reader.readAsDataURL( selectedFile.originFileObj );
        }
    };

    const handleImageLoad = ( event ) => {
        const image = event.target;
        imgRef.current = image;
    };

    // Handle cropping confirmation
    const handleModalOk = () => {
        if ( uploadObject.croppedImage ) {
            const newImageFile = new File(
                [ uploadObject.croppedImage ],
                uploadObject.imageName,
                { type: "image/png" }
            );

            // Update editDetails with the new image
            Object.keys( editDetails.cities ).forEach( ( city ) => {
                const variant = editDetails.cities[ city ][ editModalObject.variant ];
                const color = variant.colors[ editModalObject.color ];
                color.imageArray = color.imageArray ?? [];
                color.imageArray.push( newImageFile );
                color.imageNameArray.push( uploadObject.imageName );
            } );

            setEditDetails( { ...editDetails } );

            // Update `editFileList` with the cropped image
            const croppedImageURL = URL.createObjectURL( uploadObject.croppedImage );
            setEditFileList( ( prevFileList ) => [
                ...prevFileList,
                { name: uploadObject.imageName, url: croppedImageURL, thumbUrl: croppedImageURL },
            ] );

            setUploadModal( false );
            message.success( "Image successfully cropped and saved." );
        } else {
            message.error( "Please crop the image before saving." );
        }
    };

    // Handle Edit Images button
    const handleEditImagesClick = ( city, variant, colorIndex ) => {
        setEditFileList(
            editDetails.cities[ city ][ variant ].colors[ colorIndex ]
                .imageNameArray.map( ( colorImage ) => ( {
                    name: colorImage,
                    url: `https://cdn.aapli.in/Make%2F${ editDetails.make }%2FModels%2F${ editDetails.model }%2FCities%2FMumbai%2FVariants%2F${ variant }%2FColors%2F${ editDetails.cities[ city ][ variant ].colors[ colorIndex ].name }%2F${ colorImage }`,
                } ) )
        )
        setEditModalObject( { variant, color: colorIndex } );
        setEditImagesModal( true );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 1 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                getInfo();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='editing-container'>
            {contextHolder}
            <Modal
                title="Upload Image"
                open={uploadModal}
                onOk={handleModalOk}
                onCancel={event => setUploadModal( false )}
                cancelButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
                width={"800px"}
            >
                <ReactCrop
                    crop={uploadObject.crop ?? ""}
                    onChange={( newCrop ) => setUploadObject( { ...uploadObject, crop: newCrop } )}
                    onComplete={onCropComplete}
                    aspect={1.5}
                    minHeight={350}
                    minWidth={525}
                >
                    <img className='crop-img' src={uploadObject.image} onLoad={handleImageLoad} width={"100%"} crossOrigin="anonymous" alt="crop" />
                </ReactCrop>
            </Modal>
            <Modal
                title="Specification Details"
                open={petrolModal}
                onOk={event => {
                    setConfirmLoading( true );
                    if ( specificationsDetails.type === "Add" ) {
                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                            editDetails.cities[ element ][ variantName ] = {
                                specifications: specifications,
                                accessories_array: standardAccessories,
                                additional_array: additionalAccessories,
                                colors: []
                            }
                        } )
                    } else if ( specificationsDetails.type === "Edit" ) {
                        Object.keys( editDetails.cities ).forEach( ( element ) => {
                            editDetails.cities[ element ][ specificationsDetails.variant ].specifications = specifications;
                        } )
                    }
                    setEditDetails( { ...editDetails } );
                    setSpecificationsDetails( {} );
                    setSpecifications( {} );
                    setPetrolModal( false );
                    setConfirmLoading( false );
                }}
                confirmLoading={confirmLoading}
                onCancel={event => {
                    setPetrolModal( false )
                    setSpecificationsDetails( {} );
                    setSpecifications( {} );
                }}
                centered
            >
                <div className='specifications-modal-container'>
                    <input type="text" placeholder="Enter Mileage" value={specifications.mileage ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, mileage: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Kerb Weight" value={specifications.kerb_weight ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, kerb_weight: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Seat Height" value={specifications.seat_height ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, seat_height: event.target.value } );
                        }
                    }} />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Front Brake"
                        allowClear
                        value={specifications.front_brake ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'ABS', label: 'ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, front_brake: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Rear Brake"
                        allowClear
                        value={specifications.rear_brake ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'ABS', label: 'ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, rear_brake: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Brake Type"
                        allowClear
                        value={specifications.brake_type ?? []}
                        options={[
                            { value: 'Drum', label: 'Drum' },
                            { value: 'Disc', label: 'Disc' },
                            { value: 'Single Channel ABS', label: 'Single Channel ABS' },
                            { value: 'Dual Channel ABS', label: 'Dual Channel ABS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, brake_type: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Class"
                        allowClear
                        value={specifications.class ?? []}
                        options={[
                            { value: 'Adventure', label: 'Adventure' },
                            { value: 'Sports', label: 'Sports' },
                            { value: 'Cruiser', label: 'Cruiser' },
                            { value: 'Daily Commuter', label: 'Daily Commuter' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, class: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Connectivity"
                        allowClear
                        value={specifications.connectivity ?? []}
                        options={[
                            { value: 'Bluetooth', label: 'Bluetooth' },
                            { value: 'GPS', label: 'GPS' },
                            { value: 'NFS', label: 'NFS' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, connectivity: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                        mode='multiple'
                        maxTagCount="responsive"
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Start"
                        allowClear
                        value={specifications.start ?? []}
                        options={[
                            { value: 'Standard Key', label: 'Standard Key' },
                            { value: 'Keyless', label: 'Keyless' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, start: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Wheel"
                        allowClear
                        value={specifications.wheel ?? []}
                        options={[
                            { value: 'Sheet Metal', label: 'Sheet Metal' },
                            { value: 'Alloy', label: 'Alloy' },
                        ]}
                        onChange={event => {
                            setSpecifications( { ...specifications, wheel: event } );
                        }}
                        style={{
                            width: "100%",
                            textAlign: "left"
                        }}
                    />
                    <input type="text" placeholder="Enter Fuel Capacity" value={specifications.fuel_capacity ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, fuel_capacity: event.target.value } );
                        }
                    }} />
                    <input type="text" placeholder="Enter Vehicle Length" value={specifications.vehicle_length ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSpecifications( { ...specifications, vehicle_length: event.target.value } );
                        }
                    }} />
                    <JoditEditor
                        value={specifications.productSummary ?? ""}
                        tabIndex={1}
                        onBlur={newContent => setSpecifications( { ...specifications, productSummary: newContent } )}
                        onChange={newContent => setSpecifications( { ...specifications, productSummary: newContent } )}
                    />
                </div>
            </Modal>
            <Modal
                title="Add Images"
                open={imagesModal}
                // onOk={event => addModel( event )}
                onCancel={event => setImagesModal( false )}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
            >
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    listType="picture-circle"
                    fileList={fileList}
                    onPreview={handlePreview}
                    onChange={handleChange}
                >
                    {uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </Modal>
            <Modal
                title="Edit Images"
                open={editImagesModal}
                // onOk={event => addModel( event )}
                onCancel={event => setEditImagesModal( false )}
                okButtonProps={{
                    style: {
                        display: "none"
                    }
                }}
            >
                <Upload
                    action="https://www.mocky.io/v2/5cc8019d300000980a055e76"
                    headers={{
                        mode: "no-cors"
                    }}
                    listType="picture-circle"
                    fileList={editFileList}
                    onPreview={handlePreview}
                    onChange={handleEditChange}
                >
                    {uploadButton}
                </Upload>
                <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
            </Modal>
            <form onSubmit={event => {
                event.preventDefault();
                if ( colorObject.colorImage && colorObject.colorImageName ) {
                    if ( colorObject.variant !== "All" ) {
                        let object = editDetails;
                        Object.keys( object.cities ).forEach( ( element ) => {
                            let array = object.cities[ element ][ colorObject.variant ].colors;
                            array.push( {
                                name: colorObject.colorName,
                                color: colorObject.colorHex,
                                price: colorObject.colorPrice,
                                image: colorObject.colorImage,
                                imageName: colorObject.colorImageName,
                                imageArray: fileList.map( ( element ) => element.originFileObj ),
                                imageNameArray: fileList.map( ( element ) => element.name ),
                                status: "Active"
                            } )
                            object.cities[ element ][ colorObject.variant ].colors = array;
                        } )
                        setFileList( [] );
                        setColorObject( {} );
                        setEditDetails( { ...object } );
                    } else {
                        let object = editDetails;
                        Object.keys( object.cities ).forEach( ( element ) => {
                            Object.keys( object.cities[ element ] ).forEach( ( element1 ) => {
                                let array = object.cities[ element ][ element1 ].colors;
                                array.push( {
                                    name: colorObject.colorName,
                                    color: colorObject.colorHex,
                                    price: colorObject.colorPrice,
                                    image: colorObject.colorImage,
                                    imageName: colorObject.colorImageName,
                                    imageArray: fileList.map( ( element ) => element.originFileObj ),
                                    imageNameArray: fileList.map( ( element ) => element.name ),
                                    status: "Active"
                                } )
                                object.cities[ element ][ element1 ].colors = array;
                            } )
                        } )
                        setFileList( [] );
                        let color = {
                            variant: colorObject.variant,
                            colorName: "",
                            colorPrice: "",
                            colorHex: ""
                        }
                        setColorObject( { ...color } );
                        setEditDetails( { ...object } );
                    }
                } else if ( !editDetails.payoutOn || editDetails.payoutOn === "" ) {
                    error4();
                } else if ( !colorObject.colorImage || !colorObject.colorImageName ) {
                    error6();
                }
            }} className='editing-container-top-container'>
                <div>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={editDetails.make ? editDetails.make : "Select Company"}
                        allowClear
                        placeholder="Select a Company"
                        style={{ width: "180px" }}
                        onChange={async event => {
                            setSpecifications( {} );
                            setEditDetails( {} );
                            setModelsArray( [] );
                            setVariantArray( [] );
                            setStandardAccessories( standardAccessoryArray );
                            setAdditionalAccessories( additionalAccessoryArray );
                            setDisplayData( false );
                            setTimestampDisplay( "" );
                            setSpecifications( {} );
                            if ( event ) {
                                setEditDetails( { make: event } );
                                const querySnapshot1 = await getDocs( collection( firestore, "Make/" + event + "/Models/" ) );

                                let modelArray = [];
                                querySnapshot1.forEach( ( element ) => {
                                    modelArray.push( element.id );
                                } )
                                setModelsArray( [ ...modelArray ] );
                            }
                        }}
                        options={companyArray.map( ( element ) => ( { label: element, value: element } ) )}
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={editDetails.model ? editDetails.model : "Select A Model"}
                        placeholder="Select A Model"
                        style={{ width: "180px" }}
                        onChange={async event => {
                            if ( event ) {
                                if ( editDetails.make && editDetails.make !== "" ) {
                                    let modelDoc = await getDoc( doc( firestore, "Make/" + editDetails.make + "/Models", event ) );
                                    let array = schemes.filter( ( element ) => modelDoc.data().schemes?.filter( ( element1 ) => element1.id === element.id ).length === 1 );
                                    setSelectedSchemes( [ ...array ] );
                                    let object = {
                                        make: editDetails.make,
                                        model: event,
                                        cities: {},
                                        modelImageName: modelDoc.data().image,
                                        modelYoutubeLink: modelDoc.data().youtube_link,
                                        insuranceDiscount: parseFloat( modelDoc.data().insurance_discount ?? 0 ),
                                        payoutPercentage: parseFloat( modelDoc.data().payout_percentage ?? 0 ),
                                        payoutOn: modelDoc.data().payout_on,
                                        thrust: modelDoc.data().thrust,
                                    }
                                    setEditDetails( { ...object } );
                                    setTimestampDisplay( new Date( modelDoc.data().timestamp.seconds * 1000 ).toLocaleString() );
                                } else {
                                    error();
                                    setEditDetails( {
                                        make: editDetails.make,
                                        model: ""
                                    } );
                                    setSpecifications( {} );
                                    setVariantArray( [] );
                                    setStandardAccessories( standardAccessoryArray );
                                    setAdditionalAccessories( additionalAccessoryArray );
                                    setDisplayData( false );
                                }
                            } else {
                                setSpecifications( {} );
                                setEditDetails( { make: editDetails.make } );
                                setVariantArray( [] );
                                setStandardAccessories( standardAccessoryArray );
                                setAdditionalAccessories( additionalAccessoryArray );
                                setDisplayData( false );
                            }
                        }}
                        options={modelsArray.map( ( element ) => ( { label: element, value: element } ) )}
                    />
                    {/* <Button onClick={event => {
                        if ( editDetails.make && editDetails.make !== "" ) {
                            document.getElementById( "edit_model_image" ).click();
                        } else {
                            error();
                        }
                    }} className="edit_upload_model_button">
                        {editDetails.make && editDetails.model ? ( editDetails.modelImage ? "Uploaded" : "Edit Image" ) : "Upload Image"}
                    </Button>
                    <input type="file" placeholder='Image' id='edit_model_image' accept="image/png, image/jpeg" style={{ display: "none" }} onChange={handleFileChange} /> */}
                    <input type="text" style={{ width: "150px" }} placeholder='Youtube Link' className='edit_model_youtube_link' value={editDetails.modelYoutubeLink ? editDetails.modelYoutubeLink : ""} onChange={event => {
                        if ( editDetails.make && editDetails.make !== "" ) {
                            setEditDetails( { ...editDetails, modelYoutubeLink: event.target.value } );
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} />
                    <input style={{ width: "80px" }} type="text" placeholder="Enter Thrust" value={editDetails.thrust ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setEditDetails( { ...editDetails, thrust: event.target.value } );
                        }
                    }} />
                    <input type="text" style={{ width: "100px" }} placeholder='Insurance Discount' className='edit_insurance_discount' value={editDetails.insuranceDiscount ?? ""} onChange={event => {
                        if ( editDetails.make && editDetails.make !== "" && editDetails.model && editDetails.model !== "" ) {
                            if ( !isNaN( event.target.value ) ) {
                                if ( parseFloat( event.target.value ) < 100 || event.target.value === "" ) {
                                    setEditDetails( { ...editDetails, insuranceDiscount: event.target.value } );
                                } else {
                                    error8();
                                }
                            } else {
                                event.target.value = "";
                            }
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} required />
                    <input type="text" style={{ width: "100px" }} placeholder='Payout Percentage (%)' className='edit_payout_percentage' value={editDetails.payoutPercentage ?? ""} onChange={event => {
                        if ( editDetails.make && editDetails.make !== "" ) {
                            if ( !isNaN( event.target.value ) ) {
                                if ( parseFloat( event.target.value ) < 100 || event.target.value === "" ) {
                                    setEditDetails( { ...editDetails, payoutPercentage: event.target.value } );
                                } else {
                                    error9();
                                }
                            } else {
                                event.target.value = "";
                            }
                        } else {
                            event.target.value = "";
                            error();
                        }
                    }} required />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={editDetails.payoutOn ?? []}
                        placeholder="Select Payout On"
                        style={{ width: "100px" }}
                        onChange={event => {
                            if ( editDetails.make && editDetails.make !== "" ) {
                                setEditDetails( { ...editDetails, payoutOn: event } );
                            } else {
                                setEditDetails( { ...editDetails, payoutOn: "" } );
                                error();
                            }
                        }}
                        options={[
                            { label: "OD", value: "OD" },
                            { label: "Net", value: "Net" }
                        ]}
                    />
                    {timestampDisplay !== "" && <span style={{ color: "white" }}>Updated : {timestampDisplay}</span>}
                    <Button onClick={async event => {
                        if ( editDetails.make && editDetails.make !== "" && editDetails.model && editDetails.model !== "" ) {
                            document.querySelector( ".sending-loader" ).style.display = "flex";
                            let citiesDocs = await getDocs( collection( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities" ) );
                            citiesDocs.forEach( async ( city ) => {
                                let variantArrayRaw = [ "All" ];
                                editDetails.cities[ city.id ] = {};
                                let variantDocs = await getDocs( collection( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + city.id + "/Variants" ) );
                                variantDocs.forEach( async ( variant ) => {
                                    variantArrayRaw.push( variant.id );
                                    setVariantArray( [ ...variantArrayRaw ] );
                                    editDetails.cities[ city.id ][ variant.id ] = {
                                        colors: [],
                                        accessories_array: variant.data().accessories_array,
                                        additional_array: variant.data().additional_array,
                                        specifications: JSON.parse( variant.data().specifications ?? "{}" )
                                    };
                                    let colorsDocs = await getDocs( collection( firestore, "Make/" + editDetails.make + "/Models/" + editDetails.model + "/Cities/" + city.id + "/Variants/" + variant.id + "/Colors" ) );
                                    if ( colorsDocs.docs.length > 0 ) {
                                        let array = [];
                                        colorsDocs.forEach( ( color ) => {
                                            array.push( {
                                                name: color.id,
                                                color: color.data().color,
                                                price: color.data().price,
                                                imageName: color.data().image,
                                                imageNameArray: color.data().imageArray,
                                                status: color.data().status ?? "Active"
                                            } )
                                        } )
                                        editDetails.cities[ city.id ][ variant.id ].colors = array;
                                        setEditDetails( { ...editDetails } );
                                    }
                                } )
                            } )
                            document.querySelector( ".sending-loader" ).style.display = "none";
                            setDisplayData( true );
                        } else {
                            error();
                        }
                    }}>
                        Display Data
                    </Button>
                </div>
                {displayData && <div>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={standardAccessories}
                        options={standardAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setStandardAccessories( newValue );
                        }}
                        placeholder='Select Standard Accessories'
                        maxTagCount='responsive'
                    />
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={additionalAccessories}
                        options={additionalAccessoryArray.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setAdditionalAccessories( newValue );
                        }}
                        placeholder='Select Additional Accessories'
                        maxTagCount='responsive'
                    />
                    <input style={{ width: "100px" }} type="text" placeholder='Variant Name' value={variantName} onChange={event => {
                        if ( editDetails.make && editDetails.make !== "" ) {
                            setVariantName( event.target.value );
                        } else {
                            setVariantName( "" );
                            error();
                        }
                    }} />
                    <Button onClick={event => {
                        if ( variantName !== "" ) {
                            let object = {
                                type: "Add"
                            }
                            setSpecificationsDetails( { ...object } );
                            setPetrolModal( true );
                        } else {
                            error1();
                        }
                    }}>Add Specifications</Button>
                    <Button onClick={event => {
                        if ( variantName !== "" ) {
                            let object = editDetails;
                            Object.keys( object.cities ).forEach( ( element ) => {
                                object.cities[ element ][ variantName ] = {
                                    specifications: object.cities[ element ][ variantName ] ? object.cities[ element ][ variantName ].specifications : {},
                                    accessories_array: standardAccessories,
                                    additional_array: additionalAccessories,
                                    colors: []
                                }
                            } )
                            setEditDetails( { ...object } );
                            setVariantArray( current => [ ...current, variantName ] );
                            setVariantName( "" );
                        } else {
                            error1();
                        }
                    }}>Add</Button>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={colorObject.variant}
                        placeholder="Select Variant"
                        style={{ width: "100px" }}
                        onChange={event => {
                            if ( event ) {
                                setColorObject( { ...colorObject, variant: event } );
                            } else {
                                setColorObject( { ...colorObject, variant: "All" } );
                            }
                        }}
                        options={variantArray.map( ( element ) => ( { label: element, value: element } ) )}
                    />
                    <input type="text" placeholder='Color Name' value={colorObject.colorName ?? ""} onChange={event => setColorObject( { ...colorObject, colorName: event.target.value } )} required />
                    <ColorPicker showText format='hex' value={colorObject.colorHex ?? ""} onChange={event => {
                        setColorObject( { ...colorObject, colorHex: event.toHexString() } )
                    }} />
                    <input type="number" placeholder='Price' value={colorObject.colorPrice ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setColorObject( { ...colorObject, colorPrice: event.target.value } );
                        } else {
                            event.target.value = ""
                        }
                    }} required />
                    <Button onClick={event => document.getElementById( "edit_color_image" ).click()}>
                        {colorObject.colorImage && colorObject.colorImageName ? "Uploaded" : "Upload Color Image"}
                    </Button>
                    <input type="file" id='edit_color_image' accept="image/png, image/jpeg" style={{ display: "none" }} onChange={event => {
                        colorObject.colorImage = event.target.files[ 0 ];
                        colorObject.colorImageName = event.target.files[ 0 ].name;
                        setColorObject( { ...colorObject } );
                    }} />
                    <Button onClick={event => setImagesModal( true )}>
                        Upload Images
                    </Button>
                    <Button htmlType="submit">Add Color</Button>
                    <Popconfirm
                        title="Delete the Model"
                        description="Are you sure to delete this model?"
                        onConfirm={async event => {
                            if ( editDetails.make && editDetails.make !== "" && editDetails.model && editDetails.model !== "" ) {
                                messageApi.loading( "Deleting the Model..." );
                                Object.keys( editDetails.cities ).forEach( async ( element ) => {
                                    Object.keys( editDetails.cities[ element ] ).forEach( ( variant ) => {
                                        editDetails.cities[ element ][ variant ].colors.forEach( async ( element1 ) => {
                                            let vehicle = editDetails.make + " " + editDetails.model + " " + variant + " " + element1.name;
                                            await deleteDoc( doc( firestore, "/aapli_global/aapli_search/search_items", vehicle ) );
                                        } )
                                    } )
                                } )
                                await deleteDoc( doc( firestore, "Make/" + editDetails.make + "/Models", editDetails.model ) );
                                messageApi.destroy();
                                messageApi.success( "Deleted the Model." );
                                setTimeout( () => {
                                    window.location.reload();
                                }, 1000 );
                            } else {
                                error();
                            }
                        }}
                        okText="Yes"
                        cancelText="No"
                    >
                        <Button danger>Delete Model</Button>
                    </Popconfirm>
                </div>}
            </form>
            {editDetails.model && editDetails.model !== "" && <Collapse items={items} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element} expandIconPosition='end' />}
            {Object.keys( editDetails.cities ?? {} ).length !== 0 && <Collapse items={mumbai} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( editDetails.cities ?? {} ).length !== 0 && <Collapse items={newDelhi} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( editDetails.cities ?? {} ).length !== 0 && <Collapse items={chennai} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
            {Object.keys( editDetails.cities ?? {} ).length !== 0 && <Collapse items={kolkata} style={{
                width: "95%",
                background: "rgba(150, 150, 150, 0.37)"
            }} expandIcon={element1} expandIconPosition='end' />}
        </div>
    )
}

export default Editing