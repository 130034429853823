import React, { useState, useEffect } from 'react';
import './Vendors.css';
import { firestore, auth } from '../../firebase/firebaseConfig';
import { collection, getDocs, doc, addDoc, setDoc, deleteDoc, getDoc, onSnapshot } from '@firebase/firestore';
import { onAuthStateChanged } from 'firebase/auth';
import { Button, Input, Popconfirm, Select, message } from 'antd';

function Vendors () {
    let [ vendors, setVendors ] = useState( [] );
    let [ statesArray, setStatesArray ] = useState( [] );
    let [ citiesArray, setCitiesArray ] = useState( [] );
    let [ vendorObject, setVendorObject ] = useState( {} );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onLoad () {
        const querySnapshot1 = await getDocs( collection( firestore, "States" ) );
        let statesRawArray = [];
        querySnapshot1.forEach( ( element ) => {
            statesRawArray.push( element.id );
        } )
        setStatesArray( [ ...statesRawArray ] );

        onSnapshot( collection( firestore, "company" ), ( snapshot ) => {
            let vendorsArray = [];
            snapshot.forEach( ( element ) => {
                let vendorObject = {
                    "name": element.data().name,
                    "district": element.data().district,
                    "state": element.data().state,
                    "address": element.data().address,
                    "pincode": element.data().PinCode,
                    "mobile": element.data().mobile,
                    "email": element.data().email,
                    "id": element.id
                }
                vendorsArray.push( vendorObject );
            } );
            setVendors( [ ...vendorsArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'Vendor already exists in the database.',
        } );
    };

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 2 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onLoad();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className="making-container">
            {contextHolder}
            <form className='making-container-top-container' onSubmit={async event => {
                event.preventDefault();
                document.querySelector( ".sending-loader" ).style.display = "flex";
                vendorObject.mobile = "+91" + vendorObject.mobile;
                let checkDoc = await getDoc( doc( firestore, "allowed-users", vendorObject.mobile ) );

                if ( !checkDoc.exists() ) {
                    let docId = await addDoc( collection( firestore, "company" ), {
                        name: vendorObject.name
                    } )

                    await setDoc( doc( firestore, "company", docId.id ), {
                        "name": vendorObject.name,
                        "mobile": vendorObject.mobile,
                        "email": vendorObject.email,
                        "address": vendorObject.address,
                        "district": vendorObject.district,
                        "state": vendorObject.state,
                        "PinCode": vendorObject.pincode,
                        "company_id": docId.id
                    } )

                    await setDoc( doc( firestore, "allowed-users", vendorObject.mobile ), {
                        "name": "Admin",
                        "mobile": vendorObject.mobile,
                        "CompanyId": docId.id,
                        "Company": vendorObject.name
                    } );
                } else {
                    error();
                }
                document.querySelector( ".sending-loader" ).style.display = "none";
            }}>
                <Input style={{ width: "180px" }} type="text" placeholder='Enter Vendor Name' value={vendorObject.name} onChange={event => {
                    setVendorObject( { ...vendorObject, name: event.target.value } );
                }} required />
                <Input addonBefore="+91" maxLength={10} style={{ width: "180px" }} type="text" placeholder='Enter Vendor Mobile' value={vendorObject.mobile} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setVendorObject( { ...vendorObject, mobile: event.target.value } );
                    } else {
                        event.target.value = "";
                    }
                }} required />
                <Input type="email" style={{ width: "180px" }} placeholder='Enter Vendor Email' value={vendorObject.email} onChange={event => {
                    setVendorObject( { ...vendorObject, email: event.target.value } );
                }} required />
                <Input style={{ width: "180px" }} type="text" placeholder='Enter Vendor Address' value={vendorObject.address} onChange={event => {
                    setVendorObject( { ...vendorObject, address: event.target.value } );
                }} required />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={vendorObject.state ? vendorObject.state : "Select State"}
                    placeholder="Select State"
                    style={{ width: "150px" }}
                    onChange={async event => {
                        if ( event ) {
                            const querySnapshot = await getDoc( doc( firestore, "States", event ) );
                            if ( querySnapshot.data().Cities ) {
                                setCitiesArray( [ ...querySnapshot.data().Cities ] );
                            }
                            delete vendorObject.district;
                            vendorObject.state = event;
                            setVendorObject( { ...vendorObject } );
                        } else {
                            setCitiesArray( [] );
                            delete vendorObject.state;
                            delete vendorObject.district;
                            setVendorObject( { ...vendorObject } );
                        }
                    }}
                    options={statesArray.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                    allowClear
                    value={vendorObject.district ? vendorObject.district : "Select District"}
                    placeholder="Select District"
                    style={{ width: "150px" }}
                    onChange={event => {
                        if ( event ) {
                            setVendorObject( { ...vendorObject, district: event } );
                        } else {
                            delete vendorObject.district;
                            setVendorObject( { ...vendorObject } );
                        }
                    }}
                    options={citiesArray.map( ( element ) => ( { label: element, value: element } ) )}
                />
                <Input style={{ width: "100px" }} type="text" maxLength={6} placeholder='Enter Vendor Pincode' value={vendorObject.pincode} onChange={event => {
                    if ( !isNaN( event.target.value ) ) {
                        setVendorObject( { ...vendorObject, pincode: event.target.value } );
                    } else {
                        event.target.value = "";
                    }
                }} required />
                <Button htmlType='submit'>Add Vendor</Button>
            </form>
            <div className='all-make-entries-container'>
                {vendors.map( ( element ) => (
                    <div className='all-make-entry-container'>
                        <input type="text" value={element.name} readOnly />
                        <input type="text" value={element.mobile} readOnly />
                        <input type="text" value={element.email} readOnly />
                        <input type="text" value={element.address} readOnly />
                        <input type="text" value={element.district} readOnly />
                        <input type="text" value={element.state} readOnly />
                        <input type="text" value={element.pincode} readOnly />
                        <Popconfirm
                            title="Delete the Vendor"
                            description={"Are you sure to delete " + element.name + "?"}
                            onConfirm={async event => {
                                await deleteDoc( doc( firestore, "company", element.id ) );
                                await deleteDoc( doc( firestore, "allowed-users", element.mobile ) );
                            }}
                            okText="Yes"
                            cancelText="No"
                        >
                            <Button danger>Delete Vendor</Button>
                        </Popconfirm>
                    </div>
                ) )}
            </div >
        </div >
    )
}

export default Vendors