import React, { useEffect, useState } from 'react';
import "./Supplier.css";
import { firestore, auth } from "../../firebase/firebaseConfig";
import { getDocs, collection, doc, deleteDoc, getDoc, updateDoc, onSnapshot, addDoc } from '@firebase/firestore';
import { onAuthStateChanged } from "firebase/auth";
import { Button, DatePicker, Input, Modal, Select, Space, message } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend( customParseFormat );

function Suppliers () {
    let [ companies, setCompanies ] = useState( [] );
    let [ suppliers, setSuppliers ] = useState( [] );
    let [ supplierAdd, setSupplierAdd ] = useState( {} );
    let [ contactAdd, setContactAdd ] = useState( {} );
    let [ departments, setDepartments ] = useState( [] );
    const [ messageApi, contextHolder ] = message.useMessage();
    let [ modal, setModal ] = useState( false );
    let [ locations, setLocations ] = useState( [] );

    async function onload () {
        let companyDocs = await getDocs( collection( firestore, "Make" ) );
        let companyRawArray = [];
        companyDocs.forEach( ( element ) => {
            companyRawArray.push( element.id );
        } )
        setCompanies( [ ...companyRawArray ] );

        onSnapshot( collection( firestore, "GlobalConfigurations/Suppliers/Suppliers" ), ( snapshot ) => {
            let suppliersRawArray = [];
            snapshot.forEach( ( element ) => {
                suppliersRawArray.push( {
                    id: element.id,
                    data: element.data()
                } );
            } )
            setSuppliers( [ ...suppliersRawArray ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );

        onSnapshot( collection( firestore, "GlobalConfigurations/Suppliers/Locations" ), ( snapshot ) => {
            let array = [];
            snapshot.forEach( ( element ) => {
                array.push( {
                    id: element.id,
                    ...element.data()
                } );
            } )
            setLocations( [ ...array ] );
            document.querySelector( ".sending-loader" ).style.display = "none";
        } );
    }

    const error = () => {
        messageApi.open( {
            type: 'error',
            content: 'The Number is already registered and exists in the database.',
        } );
    };

    const error1 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to select a supplier before proceeding.',
        } );
    };

    const error2 = () => {
        messageApi.open( {
            type: 'error',
            content: 'You need to select a division before proceeding.',
        } );
    };

    useEffect( () => {
        document.querySelectorAll( ".ant-menu-item" )[ 10 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] );

    return (
        <div className='making-container'>
            {contextHolder}
            <Modal
                open={modal}
                onOk={async () => {
                    messageApi.loading( "Saving the Locations...", 0 );
                    locations.forEach( async ( element ) => {
                        if ( element.id ) {
                            await updateDoc( doc( firestore, "GlobalConfigurations/Suppliers/Locations", element.id ), element )
                        } else {
                            await addDoc( collection( firestore, "GlobalConfigurations/Suppliers/Locations" ), element );
                        }
                    } )
                    messageApi.destroy();
                    messageApi.success( "Saved The Locations." );
                }}
                onCancel={() => setModal( false )}
                okText="Save"
                cancelText="Close"
                centered
                width={"100%"}
            >
                <div className='locations-container'>
                    <div className='locations-header'>
                        <div>Location</div>
                        <div>Phone</div>
                        <div>Address</div>
                        <div>Map</div>
                    </div>
                    <div className='locations-list'>
                        {locations.map( ( element, index ) => (
                            <div className='locations-item'>
                                <div>
                                    <Input placeholder='Enter Location' value={element.location} onChange={( event ) => {
                                        locations[ index ].location = event.target.value;
                                        setLocations( [ ...locations ] );
                                    }}></Input>
                                </div>
                                <div>
                                    <Input placeholder='Enter Location' value={element.phone} minLength={10} maxLength={10} addonBefore="+91" onChange={( event ) => {
                                        if ( !isNaN( event.target.value ) ) {
                                            locations[ index ].phone = event.target.value;
                                            setLocations( [ ...locations ] );
                                        }
                                    }}></Input>
                                </div>
                                <div>
                                    <Input placeholder='Enter Address' value={element.address} onChange={( event ) => {
                                        locations[ index ].address = event.target.value;
                                        setLocations( [ ...locations ] );
                                    }}></Input>
                                </div>
                                <div>
                                    <Input placeholder='Enter Url' value={element.url} onChange={( event ) => {
                                        locations[ index ].url = event.target.value;
                                        setLocations( [ ...locations ] );
                                    }}></Input>
                                </div>
                            </div>
                        ) )}
                        <div className='locations-item'>
                            <Button onClick={() => {
                                if ( locations.filter( ( element1 ) => !element1.location || element1.location === "" ).length === 0 ) {
                                    let array = locations;
                                    array.push( {} );
                                    setLocations( [ ...array ] );
                                } else {
                                    messageApi.error( "New Entry Already Added." );
                                }
                            }}>Add</Button>
                        </div>
                    </div>
                </div>
            </Modal>
            <div className='making-container-top-container supplier'>
                <form onSubmit={async event => {
                    event.preventDefault();
                    document.querySelector( ".sending-loader" ).style.display = "flex";
                    let existing = await getDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers", supplierAdd.mobile ) );
                    if ( !existing.exists() ) {
                        await addDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers" ), {
                            name: supplierAdd.name,
                            mobile: supplierAdd.mobile,
                            companies: supplierAdd.companies,
                            contacts: []
                        } )
                        setSupplierAdd( {} );
                        event.target.reset();
                    } else {
                        error();
                    }
                    document.querySelector( ".sending-loader" ).style.display = "none";
                }}>
                    <Input style={{ width: "180px" }} type="text" placeholder='Enter Name' className='supplier-name' value={supplierAdd.name} onChange={event => setSupplierAdd( { ...supplierAdd, name: event.target.value } )} required />
                    <Input style={{ width: "180px" }} type="text" placeholder='Enter Trade Name' className='supplier-trade-name' value={supplierAdd.tradeName} onChange={event => setSupplierAdd( { ...supplierAdd, tradeName: event.target.value } )} />
                    <DatePicker style={{ width: "180px" }} value={!isNaN( dayjs( supplierAdd.mfgDate, "DD-MM-YYYY" ).date() ) ? dayjs( supplierAdd.mfgDate, "DD-MM-YYYY" ) : ""} placeholder='Select Date' onChange={( event ) => {
                        setSupplierAdd( { ...supplierAdd, estd: event.format( "DD-MM-YYYY" ) } );
                    }} />
                    <Input style={{ width: "180px" }} type="text" addonBefore="+91" placeholder='Enter Mobile' className='supplier-mobile' minLength={10} maxLength={10} value={supplierAdd.mobile ?? ""} required onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setSupplierAdd( { ...supplierAdd, mobile: event.target.value } )
                        }
                    }} />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        style={{
                            width: '180px',
                        }}
                        value={supplierAdd.headquarter ? ( JSON.stringify( {
                            location: supplierAdd.headquarter?.location,
                            phone: supplierAdd.headquarter?.phone,
                            address: supplierAdd.headquarter?.address,
                            url: supplierAdd.headquarter?.url
                        } ) ) : [] ?? []}
                        options={locations.map( ( element ) => ( {
                            label: element.location, value: JSON.stringify( {
                                location: element.location,
                                phone: element.phone,
                                address: element.address,
                                url: element.url
                            } )
                        } ) )}
                        onChange={( newValue ) => {
                            setSupplierAdd( { ...supplierAdd, headquarter: JSON.parse( newValue ) } );
                        }}
                        placeholder='Select Headquarter'
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={supplierAdd.warehouses?.map( ( element ) => (
                            JSON.stringify( {
                                location: element.location,
                                phone: element.phone,
                                address: element.address,
                                url: element.url
                            } )
                        ) ) ?? []}
                        options={locations.map( ( element ) => ( {
                            label: element.location, value: JSON.stringify( {
                                location: element.location,
                                phone: element.phone,
                                address: element.address,
                                url: element.url
                            } )
                        } ) )}
                        onChange={( newValue ) => {
                            setSupplierAdd( { ...supplierAdd, warehouses: newValue.map( ( element ) => JSON.parse( element ) ) } );
                        }}
                        placeholder='Select Warehouses'
                        maxTagCount='responsive'
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={supplierAdd.branches?.map( ( element ) => (
                            JSON.stringify( {
                                location: element.location,
                                phone: element.phone,
                                address: element.address,
                                url: element.url
                            } )
                        ) ) ?? []}
                        options={locations.map( ( element ) => ( {
                            label: element.location, value: JSON.stringify( {
                                location: element.location,
                                phone: element.phone,
                                address: element.address,
                                url: element.url
                            } )
                        } ) )}
                        onChange={( newValue ) => {
                            setSupplierAdd( { ...supplierAdd, branches: newValue.map( ( element ) => JSON.parse( element ) ) } );
                        }}
                        placeholder='Select Branches'
                        maxTagCount='responsive'
                    />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={supplierAdd.companies ?? []}
                        options={companies.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setSupplierAdd( { ...supplierAdd, companies: newValue } );
                        }}
                        placeholder='Select Companies'
                        maxTagCount='responsive'
                    />
                    <Button htmlType="submit">Add Supplier</Button>
                    <Button onClick={() => setModal( true )}>Locations</Button>
                </form>
                <form className='contact-add-form' onSubmit={async event => {
                    document.querySelector( ".sending-loader" ).style.display = "flex";
                    event.preventDefault();
                    if ( contactAdd.supplier && contactAdd.supplier ) {
                        if ( contactAdd.division && contactAdd.division !== "" ) {
                            let array = suppliers.filter( ( element ) => element.id === contactAdd.supplier );
                            let updateArray = array[ 0 ].data.contacts;
                            updateArray.push( {
                                name: contactAdd.name,
                                mobile: contactAdd.mobile,
                                phone: contactAdd.phone,
                                email: contactAdd.email,
                                division: contactAdd.division,
                                departments: contactAdd.departments ?? []
                            } )
                            await updateDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers", contactAdd.supplier ), {
                                contacts: updateArray
                            } );
                            setContactAdd( {} );
                            setDepartments( [] );
                            event.target.reset();
                        } else {
                            error2();
                        }
                    } else {
                        error1();
                    }
                    document.querySelector( ".sending-loader" ).style.display = "none";
                }}>
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={contactAdd.supplier ? contactAdd.supplier : "Select Supplier"}
                        placeholder="Select Supplier"
                        style={{ width: "180px" }}
                        onChange={async event => {
                            if ( event ) {
                                setContactAdd( { ...contactAdd, supplier: event } );
                            }
                        }}
                        options={suppliers.map( ( element ) => ( { label: element.data.name, value: element.id } ) )}
                    />
                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Name' className='contact-name' value={contactAdd.name} onChange={event => {
                        setContactAdd( { ...contactAdd, name: event.target.value } );
                    }} required />
                    <Input type="email" style={{ width: "180px" }} placeholder='Enter Email' className='contact-email' value={contactAdd.email} onChange={event => {
                        setContactAdd( { ...contactAdd, email: event.target.value } );
                    }} required />
                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Mobile' addonBefore="+91" minLength={10} maxLength={10} className='contact-mobile' value={contactAdd.mobile ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setContactAdd( { ...contactAdd, mobile: event.target.value } );
                        }
                    }} required />
                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Phone' addonBefore="+91" minLength={10} maxLength={10} className='contact-phone' value={contactAdd.phone ?? ""} onChange={event => {
                        if ( !isNaN( event.target.value ) ) {
                            setContactAdd( { ...contactAdd, phone: event.target.value } );
                        }
                    }} required />
                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        allowClear
                        value={contactAdd.division ? contactAdd.division : "Select Division"}
                        placeholder="Select Division"
                        style={{ width: "180px" }}
                        onChange={async event => {
                            if ( event ) {
                                setContactAdd( { ...contactAdd, division: event } );
                                if ( event === "Sales" ) {
                                    setDepartments( [ "Inventory", "Pricing", "Insurance", "Sale letter", "HSRP", "Service Book", "Account" ] );
                                } else {
                                    setDepartments( [] );
                                }
                            }
                        }}
                        options={[
                            { label: "Sales", value: "Sales" },
                            { label: "Service", value: "Service" },
                            { label: "Marketing", value: "Marketing" },
                            { label: "Branch", value: "Branch" }
                        ]}
                    />
                    {departments.length !== 0 && <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        mode='multiple'
                        style={{
                            width: '180px',
                        }}
                        value={contactAdd.departments ?? []}
                        options={departments.map( ( element ) => ( { label: element, value: element } ) )}
                        onChange={( newValue ) => {
                            setContactAdd( { ...contactAdd, departments: newValue } );
                        }}
                        placeholder='Select Departments'
                        maxTagCount='responsive'
                    />}
                    <Button htmlType="submit">Add Contact</Button>
                </form>
            </div>
            <div className="all-make-city-container" style={{ width: "95%" }}>
                Suppliers
            </div>
            <div className='all-make-entries-container'>
                {suppliers.map( ( element, index ) => (
                    <div style={{
                        display: "flex",
                        flexDirection: "column"
                    }}>
                        <div className='all-make-entry-container' style={{ width: "100%", justifyContent: "space-between" }}>
                            <div style={{
                                display: "flex",
                                gap: "1rem",
                                alignItems: "center",
                                flexWrap: "wrap",
                                width: "80%"
                            }}>
                                <Input style={{ width: "180px" }} placeholder='Enter Name' value={element.data.name} onChange={event => {
                                    suppliers[ index ].data.name = event.target.value;
                                    setSuppliers( [ ...suppliers ] );
                                }}></Input>
                                <Input style={{ width: "180px" }} type="text" placeholder='Enter Trade Name' className='supplier-trade-name' value={element.data.tradeName} onChange={event => {
                                    suppliers[ index ].data.tradeName = event.target.value;
                                    setSuppliers( [ ...suppliers ] );
                                }} required />
                                <DatePicker style={{ width: "180px" }} value={!isNaN( dayjs( element.data.estd, "DD-MM-YYYY" ).date() ) ? dayjs( element.data.estd, "DD-MM-YYYY" ) : ""} placeholder='Select Date' onChange={( event ) => {
                                    suppliers[ index ].data.estd = event.format( "DD-MM-YYYY" );
                                    setSuppliers( [ ...suppliers ] );
                                }} />
                                <Input style={{ width: "180px" }} type="text" addonBefore="+91" placeholder='Enter Mobile' className='supplier-mobile' minLength={10} maxLength={10} value={element.data.mobile} required onChange={event => {
                                    if ( !isNaN( event.target.value ) ) {
                                        suppliers[ index ].data.mobile = event.target.value;
                                        setSuppliers( [ ...suppliers ] );
                                    }
                                }} />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    style={{
                                        width: '180px',
                                    }}
                                    value={element.data.headquarter ? ( JSON.stringify( {
                                        location: element.data.headquarter?.location,
                                        phone: element.data.headquarter?.phone,
                                        address: element.data.headquarter?.address,
                                        url: element.data.headquarter?.url
                                    } ) ) : [] ?? []}
                                    options={locations.map( ( element ) => ( {
                                        label: element.location, value: JSON.stringify( {
                                            location: element.location,
                                            phone: element.phone,
                                            address: element.address,
                                            url: element.url
                                        } )
                                    } ) )}
                                    onChange={( newValue ) => {
                                        suppliers[ index ].data.headquarter = JSON.parse( newValue );
                                        setSuppliers( [ ...suppliers ] );
                                    }}
                                    placeholder='Select Headquarter'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    mode='multiple'
                                    style={{
                                        width: '180px',
                                    }}
                                    value={element.data.warehouses?.map( ( element ) => (
                                        JSON.stringify( {
                                            location: element.location,
                                            phone: element.phone,
                                            address: element.address,
                                            url: element.url
                                        } )
                                    ) ) ?? []}
                                    options={locations.map( ( element ) => ( {
                                        label: element.location, value: JSON.stringify( {
                                            location: element.location,
                                            phone: element.phone,
                                            address: element.address,
                                            url: element.url
                                        } )
                                    } ) )}
                                    onChange={( newValue ) => {
                                        suppliers[ index ].data.warehouses = newValue.map( ( element ) => JSON.parse( element ) );
                                        setSuppliers( [ ...suppliers ] );
                                    }}
                                    placeholder='Select Warehouses'
                                    maxTagCount='responsive'
                                />
                                <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                    mode='multiple'
                                    style={{
                                        width: '180px',
                                    }}
                                    value={element.data.branches?.map( ( element ) => (
                                        JSON.stringify( {
                                            location: element.location,
                                            phone: element.phone,
                                            address: element.address,
                                            url: element.url
                                        } )
                                    ) ) ?? []}
                                    options={locations.map( ( element ) => ( {
                                        label: element.location, value: JSON.stringify( {
                                            location: element.location,
                                            phone: element.phone,
                                            address: element.address,
                                            url: element.url
                                        } )
                                    } ) )}
                                    onChange={( newValue ) => {
                                        suppliers[ index ].data.branches = newValue.map( ( element ) => JSON.parse( element ) );
                                        setSuppliers( [ ...suppliers ] );
                                    }}
                                    placeholder='Select Branches'
                                    maxTagCount='responsive'
                                />
                            </div>
                            <Space>
                                <Button onClick={async event => {
                                    document.querySelector( ".sending-loader" ).style.display = "flex";
                                    await updateDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers", suppliers[ index ].id ), suppliers[ index ].data );
                                    document.querySelector( ".sending-loader" ).style.display = "none";
                                }}>Save</Button>
                                <Button onClick={event => {
                                    if ( event.target.tagName === "SPAN" ) {
                                        event.target.parentNode.parentNode.parentNode.parentNode.parentNode.childNodes[ 1 ]?.classList.toggle( "active" );
                                    } else {
                                        event.target.parentNode.parentNode.parentNode.parentNode.childNodes[ 1 ]?.classList.toggle( "active" );
                                    }
                                }}>Contacts</Button>
                                <Button onClick={async event => {
                                    document.querySelector( ".sending-loader" ).style.display = "flex";
                                    await deleteDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers", suppliers[ index ].id ) );
                                    document.querySelectorAll( ".agencies-container" ).forEach( ( element ) => {
                                        element.classList.remove( "active" );
                                    } );
                                    document.querySelector( ".sending-loader" ).style.display = "none";
                                }} danger>Delete Supplier</Button>
                            </Space>
                        </div>
                        <Space direction='vertical' style={{
                            marginTop: "0.5rem",
                            marginBottom: "2rem"
                        }} className='agencies-container'>
                            {element.data.contacts.map( ( element1, index1 ) => (
                                <div className='all-make-city-container'>
                                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Name' className='contact-name' value={element1.name} onChange={event => {
                                        suppliers[ index ].data.contacts[ index1 ].name = event.target.value;
                                        setSuppliers( [ ...suppliers ] );
                                    }} required />
                                    <Input type="email" style={{ width: "180px" }} placeholder='Enter Email' className='contact-email' value={element1.email} onChange={event => {
                                        suppliers[ index ].data.contacts[ index1 ].email = event.target.value;
                                        setSuppliers( [ ...suppliers ] );
                                    }} required />
                                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Mobile' addonBefore="+91" minLength={10} maxLength={10} className='contact-mobile' value={element1.mobile ?? ""} onChange={event => {
                                        if ( !isNaN( event.target.value ) ) {
                                            suppliers[ index ].data.contacts[ index1 ].mobile = event.target.value;
                                            setSuppliers( [ ...suppliers ] );
                                        }
                                    }} required />
                                    <Input type="text" style={{ width: "180px" }} placeholder='Enter Phone' addonBefore="+91" minLength={10} maxLength={10} className='contact-phone' value={element1.phone ?? ""} onChange={event => {
                                        if ( !isNaN( event.target.value ) ) {
                                            suppliers[ index ].data.contacts[ index1 ].phone = event.target.value;
                                            setSuppliers( [ ...suppliers ] );
                                        }
                                    }} required />
                                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                        value={element1.location ?? []}
                                        placeholder="Select Location"
                                        style={{ width: "180px" }}
                                        onChange={event => {
                                            suppliers[ index ].data.contacts[ index1 ].location = event;
                                            setSuppliers( [ ...suppliers ] );
                                        }}
                                        options={locations.map( ( element ) => ( { label: element.location, value: element.location } ) )}
                                    />
                                    <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                        allowClear
                                        value={element1.division ? element1.division : "Select Division"}
                                        placeholder="Select Division"
                                        style={{ width: "180px" }}
                                        onChange={async event => {
                                            if ( event ) {
                                                if ( event === "Sales" ) {
                                                    suppliers[ index ].data.contacts[ index1 ].division = event;
                                                    suppliers[ index ].data.contacts[ index1 ].departments = [];
                                                } else {
                                                    suppliers[ index ].data.contacts[ index1 ].departments = [];
                                                    suppliers[ index ].data.contacts[ index1 ].division = event;
                                                }
                                                setSuppliers( [ ...suppliers ] );
                                            }
                                        }}
                                        options={[
                                            { label: "Sales", value: "Sales" },
                                            { label: "Service", value: "Service" },
                                            { label: "Marketing", value: "Marketing" },
                                            { label: "Branch", value: "Branch" }
                                        ]}
                                    />
                                    {element1.division === "Sales" && <Select
                    showSearch
                    filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                                        mode='multiple'
                                        style={{
                                            width: '180px',
                                        }}
                                        value={element1.departments ?? []}
                                        options={[ "Inventory", "Pricing", "Insurance", "Sale letter", "HSRP", "Service Book", "Account" ].map( ( element ) => ( { label: element, value: element } ) )}
                                        onChange={( newValue ) => {
                                            suppliers[ index ].data.contacts[ index1 ].departments = newValue;
                                            setSuppliers( [ ...suppliers ] );
                                        }}
                                        placeholder='Select Departments'
                                        maxTagCount='responsive'
                                    />}
                                    <div>
                                        <Button onClick={async event => {
                                            document.querySelector( ".sending-loader" ).style.display = "flex";
                                            let array = element.data.contacts;
                                            array.splice( index1, 1 );
                                            await updateDoc( doc( firestore, "GlobalConfigurations/Suppliers/Suppliers", suppliers[ index ].id ), {
                                                contacts: array
                                            } )
                                            document.querySelector( ".sending-loader" ).style.display = "none";
                                        }} danger>Delete Contact</Button>
                                    </div>
                                </div>
                            ) )}
                        </Space>
                    </div>
                ) )}
            </div>
        </div>
    )
}

export default Suppliers