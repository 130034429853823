import { onAuthStateChanged } from 'firebase/auth';
import React, { useEffect, useState } from 'react'
import { auth } from "../../firebase/firebaseConfig";
import { Button, DatePicker, Input, Select, message } from 'antd';
import pincodes from "./pincodes.json";
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

dayjs.extend( customParseFormat );

function Booking () {
    let [ object, setObject ] = useState( {} );
    let [ make, setMake ] = useState( [] );
    let [ models, setModels ] = useState( [] );
    let [ variants, setVariants ] = useState( [] );
    let [ colors, setColors ] = useState( [] );
    let [ delivery, setDelivery ] = useState( {} );
    let [ allSchemes, setAllSchemes ] = useState( [] );
    let [ allDisplaySchemes, setAllDisplaySchemes ] = useState( [] );
    let [ scheme, setScheme ] = useState( {} );

    let [ detailsSet, setDetails ] = useState( false );

    let [ payment, setPayment ] = useState( undefined );
    let allMonths = [ 3, 6, 9, 12, 18, 24, 30, 36, 42, 48, 54, 60 ];

    let [ check, setCheck ] = useState( false );
    const [ messageApi, contextHolder ] = message.useMessage();

    async function onload () {
        let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getMake" );
        let data = await res.json();
        setMake( [ ...data.array ] );

        document.querySelector( ".sending-loader" ).style.display = "none";
    }

    function recalculate ( downPaymentRaw ) {
        let schemesArray = [];
        let months = [ 3, 6, 9, 12, 18, 24, 30, 36, 42, 48, 54, 60 ];
        allSchemes.forEach( async ( element1 ) => {
            let objectRaw = element1;
            let chargesArray = objectRaw.chargesArray;

            let tenure = objectRaw.tenure.split( "-" );
            let time = months.filter( ( month ) => month >= tenure[ 0 ] && month <= tenure[ 1 ] );
            let rate = parseFloat( objectRaw.rate ) / 1200;

            let leftAmount = object.discount_price - parseInt( downPaymentRaw );
            let emi_array = [];

            time.forEach( ( element3 ) => {
                let chargeFinal = 0;
                let chargeObject = {};
                chargesArray.forEach( ( element4 ) => {
                    let chargeRaw = 0;
                    let type = element4.type;
                    if ( type === "Charge" ) {
                        chargeRaw = Math.round( element4.chargeType === "Fixed" ? parseFloat( element4.amount ) : ( object.discount_price * parseFloat( element4.amount ) / 100 ) );
                    } else {
                        let year = Math.ceil( element3 / 12 );
                        if ( year === 1 ) {
                            year = "first";
                        } else if ( year === 2 ) {
                            year = "second";
                        } else if ( year === 3 ) {
                            year = "third";
                        } else if ( year === 4 ) {
                            year = "fourth";
                        } else if ( year === 5 ) {
                            year = "fifth";
                        }
                        let array = element4.amounts;
                        array = array.filter( ( element5 ) => leftAmount >= element5.range?.split( "-" )[ 0 ] && leftAmount <= element5.range?.split( "-" )[ 1 ] );
                        if ( array.length !== 0 ) {
                            chargeRaw = Math.round( element4.chargeType === "Fixed" ? parseFloat( array[ 0 ][ year ] ) : ( object.discount_price * parseFloat( array[ 0 ][ year ] ) / 100 ) );
                        }
                    }
                    chargeFinal += chargeRaw;
                    chargeObject[ element4.name ] = chargeRaw.toFixed( 2 );
                } );
                let loanAmount = Math.round( leftAmount + chargeFinal );
                let time_raw = element3 - parseInt( objectRaw.advanceEMI )
                if ( time_raw <= 0 ) {
                    time_raw = element3;
                }
                if ( objectRaw.schemeType === "Flat" ) {
                    if ( element3 % 2 === 0 ) {
                        let emi = Math.round( ( ( ( loanAmount * rate ) * element3 ) + loanAmount ) / element3 );
                        let extra = Math.round( ( emi * element3 ) - object.discount_price + parseInt( downPaymentRaw ) );
                        let cost = Math.round( ( emi * element3 ) + parseInt( downPaymentRaw ) );
                        emi_array.push( {
                            month: element3,
                            emi: emi.toFixed( 2 ),
                            extra: extra.toFixed( 2 ),
                            cost: cost.toFixed( 2 ),
                            showRate: objectRaw.show_rate,
                            loanAmount: loanAmount.toFixed( 2 ),
                            leftAmount: leftAmount.toFixed( 2 ),
                            charge: chargeFinal.toFixed( 2 ),
                            chargeObject: chargeObject
                        } );
                    } else {
                        let emi = Math.round( ( ( ( loanAmount * rate ) * ( element3 + 1 ) ) + loanAmount ) / element3 );
                        let extra = Math.round( ( emi * element3 ) - object.discount_price + parseInt( downPaymentRaw ) );
                        let cost = Math.round( ( emi * element3 ) + parseInt( downPaymentRaw ) );
                        emi_array.push( {
                            month: element3,
                            emi: emi.toFixed( 2 ),
                            extra: extra.toFixed( 2 ),
                            cost: cost.toFixed( 2 ),
                            showRate: objectRaw.show_rate,
                            loanAmount: loanAmount.toFixed( 2 ),
                            leftAmount: leftAmount.toFixed( 2 ),
                            charge: chargeFinal.toFixed( 2 ),
                            chargeObject: chargeObject
                        } );
                    }
                } else {
                    let emi = Math.round( ( loanAmount * rate * Math.pow( ( 1 + rate ), element3 ) ) / ( Math.pow( 1 + rate, element3 ) - 1 ) )
                    let extra = Math.round( ( emi * element3 ) - object.discount_price + parseInt( downPaymentRaw ) );
                    let cost = Math.round( ( emi * element3 ) + parseInt( downPaymentRaw ) );
                    emi_array.push( {
                        month: element3,
                        emi: emi.toFixed( 2 ),
                        extra: extra.toFixed( 2 ),
                        cost: cost.toFixed( 2 ),
                        showRate: objectRaw.show_rate,
                        loanAmount: loanAmount.toFixed( 2 ),
                        leftAmount: leftAmount.toFixed( 2 ),
                        charge: chargeFinal.toFixed( 2 ),
                        chargeObject: chargeObject
                    } );
                }
                objectRaw.leftAmount = leftAmount.toFixed( 2 );
            } )
            objectRaw.tenureArray = time;
            objectRaw.emiArray = [ ...emi_array ];
            schemesArray.push( objectRaw );
            setAllSchemes( [ ...schemesArray ] );
            setAllDisplaySchemes( [ ...schemesArray ] );
        } )
    }

    useEffect( () => {
        document.querySelector( ".sending-loader" ).style.display = "flex";
        document.querySelectorAll( ".ant-menu-item" )[ 15 ].click();
        onAuthStateChanged( auth, ( user ) => {
            if ( user ) {
                onload();
            } else {
                window.location.href = "/login";
            }
        } );
    }, [] )

    return (
        <div className='making-container'>
            {contextHolder}
            <div className="all-make-city-container" style={{ width: "95%" }}>
                New Booking
            </div>
            <div className='all-make-entries-container'>
                <form onSubmit={async ( event ) => {
                    event.preventDefault();
                    messageApi.loading( "Adding a booking...", 0 );
                    if ( payment === "Cash" ) {
                        await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/admin/" + object.make + "/" + object.model + "/" + object.variant + "/" + object.color + "/" + object.uid + "/cashBooking", {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify( object )
                        } );
                    } else {
                        console.log( object );
                        await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/admin/" + object.make + "/" + object.model + "/" + object.variant + "/" + object.color + "/" + object.uid + "/emiBooking", {
                            method: "POST",
                            headers: {
                                'Content-Type': 'application/json'
                            },
                            body: JSON.stringify( {
                                data: object,
                                scheme
                            } )
                        } );
                    }

                    setObject( {} );
                    setScheme( {} );
                    setDelivery( {} );
                    setModels( [] );
                    setVariants( [] );
                    setColors( [] );
                    setAllDisplaySchemes( [] );
                    setAllSchemes( [] );
                    setPayment( undefined );
                    setCheck( false );

                    messageApi.destroy();
                    messageApi.success( "Booking created successfully" );
                }} style={{ alignItems: "center", width: "100%", flexWrap: "wrap" }} className='all-make-entry-container'>
                    <Input type="tel" style={{ width: "230px" }} placeholder='Enter Phone' addonBefore="+91" value={object.phone} maxLength={10} minLength={10} onChange={( event ) => {
                        if ( !isNaN( event.target.value ) ) {
                            setObject( { ...object, phone: event.target.value } );
                        }
                    }} required></Input>
                    {!check && <Button onClick={async () => {
                        messageApi.loading( "Checking User...", 0 );
                        await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getUser?phoneNumber=91" + object.phone )
                            .then( res => res.json() )
                            .then( data => {
                                if ( !data.error ) {
                                    object.name = data.name;
                                    object.email = data.email;
                                    object.pincode = data.pincode;
                                    object.rto = data.rto;
                                    object.zone = data.zone;
                                    object.uid = data.id;
                                    setObject( { ...object } );
                                    setCheck( true );
                                    messageApi.destroy();
                                    messageApi.success( "User Data Fetched." );
                                } else {
                                    setCheck( false );
                                    messageApi.destroy();
                                    messageApi.error( "User Doesn't Exist." );
                                }
                            } )
                    }}>Check User</Button>}
                    <Input style={{ width: "230px" }} placeholder='Enter Name' value={object.name} onChange={( event ) => {
                        setObject( { ...object, name: event.target.value } );
                    }} required disabled={!check}></Input>
                    <Input type="email" style={{ width: "230px" }} placeholder='Enter Email' value={object.email} onChange={( event ) => {
                        setObject( { ...object, name: event.target.value } );
                    }} disabled={!check}></Input>
                    <Input type="tel" style={{ width: "230px" }} placeholder='Enter Pincode' value={object.pincode} maxLength={6} minLength={6} onChange={( event ) => {
                        if ( !isNaN( event.target.value ) ) {
                            setObject( { ...object, pincode: event.target.value } );
                        }
                        if ( event.target.value.length === 6 ) {
                            let array = pincodes.filter( ( element ) => element.pincode === event.target.value );
                            if ( array.length !== 0 ) {
                                let objectRaw = array[ 0 ];
                                object.pincode = objectRaw.pincode;
                                object.rto = objectRaw.rto;
                                object.zone = objectRaw.zone;
                                setObject( { ...object } );
                            } else {
                                object.pincode = event.target.value;
                                object.rto = "Not Deliverable";
                                object.zone = "Not Deliverable";
                                setObject( { ...object } );
                            }
                        }
                    }} required disabled={!check}></Input>
                    <Input style={{ width: "230px" }} placeholder='Enter Rto' value={object.rto} readOnly disabled={!check}></Input>
                    <Input style={{ width: "230px" }} placeholder='Enter Zone' value={object.zone} readOnly disabled={!check}></Input>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.make ?? []}
                        placeholder="Select Make"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            setObject( { ...object, make: event } );
                            let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getModels?make=" + event );
                            let data = await res.json();
                            setModels( [ ...data.array ] );
                        }}
                        options={make.map( ( element ) => ( { label: element, value: element } ) )}
                        disabled={!check}
                    ></Select>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.model ?? []}
                        placeholder="Select Model"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            setObject( { ...object, model: event } );
                            let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getVariants?make=" + object.make + "&model=" + event );
                            let data = await res.json();

                            setVariants( [ ...data.array ] );
                        }}
                        options={models.map( ( element ) => ( { label: element, value: element } ) )}
                        disabled={!check}
                    ></Select>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.variant ?? []}
                        placeholder="Select Variant"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            setObject( { ...object, variant: event } );
                            let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/getColors?make=" + object.make + "&model=" + object.model + "&variant=" + event );
                            let data = await res.json();

                            setColors( [ ...data.array ] );
                        }}
                        options={variants.map( ( element ) => ( { label: element, value: element } ) )}
                        disabled={!check}
                    ></Select>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.color ?? []}
                        placeholder="Select Color"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            let res1 = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/" + object.make + "/" + object.model + "/" + object.variant + "/" + event + "/details?userId=" + object.uid );
                            let data1 = await res1.json();
                            await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/startBooking", {
                                method: "POST",
                                headers: {
                                    'Content-Type': 'application/json'
                                },
                                body: JSON.stringify( {
                                    userId: auth.currentUser.uid,
                                    make: object.make,
                                    model: object.model,
                                    variant: object.variant,
                                    color: event,
                                    image: data1.image,
                                    price: data1.price
                                } )
                            } );
                            let obj = {
                                Standard: data1.standard_delivery_charge,
                                Express: data1.express_delivery_charge,
                                Zhatpat: data1.zhatpat_delivery_charge
                            }
                            setDelivery( { ...obj } );
                            object.discount_price = data1.discount_price;
                            object.mode = "Standard Delivery";
                            object.charge = data1.standard_delivery_charge;
                            object.color = event;
                            setObject( { ...object } );
                        }}
                        options={colors.map( ( element ) => ( { label: element, value: element } ) )}
                        disabled={!check}
                    ></Select>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.mode ?? []}
                        placeholder="Select Delivery"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            object.mode = event;
                            object.charge = delivery[ event.split( " " )[ 0 ] ];
                            setObject( { ...object } )
                        }}
                        options={Object.keys( delivery ).map( ( element ) => ( { label: element + " Delivery", value: element + " Delivery" } ) )}
                        disabled={!check}
                    ></Select>
                    <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={payment ?? []}
                        placeholder="Select Payment"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            messageApi.loading( "Setting Payment...", 0 );
                            setPayment( event );
                            if ( event === "EMI" ) {
                                let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/" + object.make + "/" + object.model + "/" + object.variant + "/" + object.color + "/" + object.uid + "/getEmi" );
                                let data = await res.json();
                                object.tenure = 24;
                                object.downPayment = 99;
                                setObject( { ...object } );
                                setAllSchemes( [ ...data.schemes ] );
                                setAllDisplaySchemes( [ ...data.schemes ] );
                            } else {
                                delete object.tenure;
                                delete object.downPayment;
                                setObject( { ...object } )
                                setAllSchemes( [] );
                                setAllDisplaySchemes( [] );
                            }

                            messageApi.destroy();
                            messageApi.success( "Payment Set Successfully" );
                        }}
                        options={[
                            { label: "Cash", value: "Cash" },
                            { label: "EMI", value: "EMI" },
                        ]}
                        disabled={!check}
                    ></Select>
                    {payment === "EMI" && <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        value={object.tenure ?? []}
                        placeholder="Select Tenure"
                        style={{ width: "230px" }}
                        onChange={async ( event ) => {
                            setObject( { ...object, tenure: event } );
                            setScheme( {} );
                        }}
                        options={allMonths.map( ( element ) => ( { label: element, value: element } ) )}
                        disabled={!check}
                    ></Select>}
                    {payment === "EMI" && <Input style={{ width: "230px" }} placeholder='Enter DownPayment' addonBefore="&#8377;" value={object.downPayment} onChange={( event ) => {
                        if ( !isNaN( event.target.value ) ) {
                            recalculate( event.target.value );
                            setObject( { ...object, downPayment: event.target.value } );
                            setScheme( {} );
                        }
                    }} required disabled={!check}></Input>}
                    {payment === "EMI" && <Select
                        showSearch
                        filterOption={( input, option ) => ( option?.label ?? '' )?.toLowerCase().includes( input.toLowerCase() )}
                        placeholder="Select Scheme"
                        style={{ width: "230px" }}
                        onChange={( event ) => {
                            let obj = JSON.parse( event );
                            obj.downPayment = object.downPayment;
                            obj.emi = obj.emiArray?.filter( ( element1 ) => element1.month === object.tenure )[ 0 ];
                            delete obj.emiArray;
                            setScheme( { ...obj } );
                        }}
                        options={allDisplaySchemes.filter( ( element ) => ( element.emiArray?.filter( ( element1 ) => element1.month === object.tenure ).length === 1 ) ).filter( ( element ) => ( ( object.discount_price * ( parseFloat( element.maxLoanPercent?.split( "-" )[ 1 ] ) / 100 ) ) >= ( object.discount_price - object.downPayment ) ) && ( object.discount_price * ( parseFloat( element.maxLoanPercent?.split( "-" )[ 0 ] ) / 100 ) ) <= ( object.discount_price - object.downPayment ) ).sort( ( a, b ) => ( a.emiArray?.filter( ( element ) => element.month === object.tenure )[ 0 ]?.emi ) - ( b.emiArray?.filter( ( element ) => element.month === object.tenure )[ 0 ]?.emi ) ).map( ( element1 ) => ( { label: element1.bankName + " - " + element1.scheme, value: JSON.stringify( element1 ) } ) )}
                        disabled={!check}
                    ></Select>}
                    {payment === "EMI" && <Input style={{ width: "230px" }} placeholder='Enter Zone' addonBefore="&#8377;" value={scheme.emi?.emi ?? 0} readOnly disabled={!check}></Input>}
                    <Input style={{ width: "230px" }} placeholder='Enter Zone' addonBefore="&#8377;" value={( object.discount_price ?? 0 ) + ( object.charge ?? 0 )} readOnly disabled={!check}></Input>
                    <Button onClick={async ( event ) => {
                        messageApi.loading( "Setting Details for Booking...", 0 );
                        if ( payment === "Cash" ) {
                            let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/" + object.make + "/" + object.model + "/" + object.variant + "/" + object.color + "/" + object.uid + "/payment-cash" );
                            let data = await res.json();
                            setObject( { ...object, ...data } );
                        } else {
                            let res = await fetch( "https://aapli-main-server-1036279390366.asia-south1.run.app/" + object.make + "/" + object.model + "/" + object.variant + "/" + object.color + "/" + object.uid + "/payment-cash" );
                            let data = await res.json();
                            setObject( { ...object, ...data } );
                        }

                        setDetails( true );

                        messageApi.destroy();
                        messageApi.success( "Details Set Successfully" );
                    }}>Set Details</Button>
                    <DatePicker style={{ width: "230px" }} value={object.date ? dayjs( dayjs( object.date ).format( "DD/MM/YYYY" ), "DD/MM/YYYY" ) : ""} placeholder='Select Date' onChange={( event ) => {
                        setObject( { ...object, date: event.toDate() } );
                    }} format={"DD/MM/YYYY"} disabled={!check} />
                    {detailsSet && <Button htmlType='submit'>Add Booking</Button>}
                </form>
            </div>
        </div>
    )
}

export default Booking